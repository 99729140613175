// ChildInfo.js
import React from 'react';
import CustomCheckbox from '../CustomCheckbox';

const ChildMain = ({ title, formData, handleChange, namePrefix,Kontrollkästchen }) => {
  return (
    <div className='person'>
      <h3 className="form-section-header">{title}</h3>
      
      {/* Familienversicherung Start Date */}
      <label className='label-Big'>
        Beginn der Familienversicherung (TT/MM/JJ)
        <input
          name={`Datum_kind${namePrefix}_begin`}
          value={formData[`Datum_kind${namePrefix}_begin`]}
          onChange={handleChange}
          type="text"
          className="form-input"
        />
      </label>

      {/* Child's Name and Surname */}
      <div className='namen'>
        <label className='label-Big'>
          Kind Name
          <input
            name={`kind${namePrefix}_name`}
            value={formData[`kind${namePrefix}_name`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>

        <label className='label-Big'>
          Kind Vorname
          <input
            name={`kind${namePrefix}_vorname`}
            value={formData[`kind${namePrefix}_vorname`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>
      </div>

      {/* Gender Selection */}
      <div className='select-dropdown'>
        <select
          name={`Geschlecht_Lebenspartner_${namePrefix}`}
          value={formData[`Geschlecht_Lebenspartner_${namePrefix}`]}
          onChange={handleChange}
        >
          <option value="">Geschlecht</option>
          <option value="Auswahl1">M</option>
          <option value="3">W</option>
        </select>
      </div>

      {/* Birth Date */}
      <label className='label-Big'>
        Geburtsdatum
        <input
          name={`kind${namePrefix}_Geburtsdatum`}
          value={formData[`kind${namePrefix}_Geburtsdatum`]}
          onChange={handleChange}
          type="text"
          className="form-input"
        />
      </label>

      {/* Address */}
      <label className='label-Big'>
        Anschrift
        <input
          name={`kind${namePrefix}_Address`}
          value={formData[`kind${namePrefix}_Address`]}
          onChange={handleChange}
          type="text"
          className="form-input"
        />
      </label>

      {/* Relationship Dropdown */}
      <label className='label-Big'>Verwandtschaftsverhältnis:</label>
      <div className="select-dropdown">
        <select
          name={`Verwandtschaft_Kind${namePrefix}`}
          value={formData[`Verwandtschaft_Kind${namePrefix}`]}
          onChange={handleChange}
        >
          <option value="">none</option>
          <option value="Auswahl1">leibliches</option>
          <option value="Auswahl2">Stiefkind</option>
          <option value="3">Enkelkind</option>
        </select>
      </div>

      {/* Checkbox for Related Partner */}
      <div className="checkbox-group">
        <CustomCheckbox
          label="Ist der/die Ehe-/Lebenspartner/-in mit dem Kind verwandt?"
          name={`${Kontrollkästchen}`}
          checked={formData[`${Kontrollkästchen}`]}
          onChange={handleChange}
          className="form-checkbox"
        />
      </div>
    </div>
  );
};

export default ChildMain;
