import React, { useEffect, useState } from 'react';
import './AllClients.css';
import { useNavigate } from 'react-router-dom';
import { getClientsForall,deleteClient,getAllEmployees } from '../../services/api';
import { FaArrowRight } from 'react-icons/fa';

const AllClients = () => {
    const [clients, setClients] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayedCount, setDisplayedCount] = useState(0);
    const [money, setMoney] = useState(0);
    const [animateCount, setAnimateCount] = useState(false);
    const [kindNum, setKindNum] = useState(0);
    const [kindNumOver15, setKindNumOver15] = useState(0);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);  // Keep track of which client to delete
   
    const [kundeFilter, setKundeFilter] = useState('');
    const [nachnameFilter, setNachnameFilter] = useState('');
    const [monthFilter, setMonthFilter] = useState(() => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    });
    const [employeeFilter, setEmployeeFilter] = useState(''); // New state for employee filter
    const [sortField, setSortField] = useState('submite_date'); // Default sort field
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const getAge = (birthdate) => {
        if (!birthdate) return 0;
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth();
        if (month < birthDate.getMonth() || (month === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    const getName=(id)=>{
    const selectedEmployee = employees.find(emp => emp.id == id);
   
    return selectedEmployee.username.split("@")[0];
}
    const getChildInfo = (client) => {
        const childrenArray = Array.isArray(client.children) ? client.children : [];
        const totalChildren = childrenArray.filter(child => child?.birthdate).length;
        const childrenOver15 = childrenArray.filter(child => child?.birthdate && getAge(child.birthdate) > 15);
        const numAufname = childrenArray.filter(child => child?.aufname && child.aufname==true);
        console.log(numAufname.length)
        let totalAuf=numAufname.length+1;
        if(client.w_aufname==true){
            totalAuf=totalAuf+1;
        }
        return {
            totalChildren,
            childrenOver15: childrenOver15.length,
            aufNamen: numAufname.length,
            totalAuf
        };
    };
    const increaseAmount = (num) => {
        let startCount = 0;
        const endCount = num;
    
        if (endCount > 0) {
            const duration = 2000; // Faster animation duration in milliseconds
            const intervalTime = 20; // Run every 50 milliseconds
            const increment = Math.ceil(endCount / (duration / intervalTime)); // Larger increment for faster updates
    
            const interval = setInterval(() => {
                startCount += increment;
                if (startCount >= endCount) {
                    startCount = endCount;
                    clearInterval(interval); // Stop when we reach the final count
                }
                setDisplayedCount(startCount); // Update the displayed count
                setMoney(startCount * 20); // Update money count based on filtered Aufträge
                setAnimateCount(true); // Trigger animation
                setTimeout(() => setAnimateCount(false), 1000); // Reset animation state
            }, intervalTime); // Run every 50 milliseconds for smoother updates
    
            return () => clearInterval(interval); // Clean up on unmount
        } else {
            setMoney(startCount * 20);
            setDisplayedCount(startCount);
        }
    };
    
    
    useEffect(() => {
        const filteredClients = getFilteredAntrags(clients);
    
        // Calculate the total amount: 1 for each client, plus 1 for each child older than 15
        let totalAmount = 0;
    
        filteredClients.forEach(client => {
            if(client.w_aufname==true){
                totalAmount+=1;
            }
            // Add 1 for each client
            totalAmount += 1;
    
            // Add 1 for each child older than 15
            const { aufNamen } = getChildInfo(client); // Get the count of children over 15
            totalAmount += aufNamen; // Add children over 15 count to totalAmount
        });
    
        // Animate the amount
        increaseAmount(totalAmount); // Call the animation function with the calculated totalAmount
    }, [clients, monthFilter, kundeFilter, nachnameFilter, employeeFilter]);
    
    
    useEffect(() => {
        const totalChildren = clients.reduce((total, client) => total + getChildInfo(client).totalChildren, 0);
        const totalChildrenOver15 = clients.reduce((total, client) => total + getChildInfo(client).childrenOver15, 0);
        setKindNum(totalChildren);
        setKindNumOver15(totalChildrenOver15);
    }, [clients]);


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await getAllEmployees();
               
                setEmployees(response.data); // Set employees in state
            } catch (err) {
                console.error('Error fetching employees:', err);
               
            }
        };
        fetchEmployees(); // Call fetch function
    }, []); // Run on mount only

  


    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        setLoading(true);
        try {
            const response = await getClientsForall();
            setClients(response.data);
           
        } catch (err) {
            setError('Failed to fetch clients. Please check your permissions.');
        } finally {
            setLoading(false);
        }
    };

    const getMonthRange = (month) => {
        const date = new Date(month);
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return { startDate, endDate };
    };

    const getFilteredAntrags = (clients) => {
        return clients.filter(client => {
            const matchesKunde = kundeFilter === '' || (client.vornname && client.vornname.toLowerCase().includes(kundeFilter.toLowerCase()));
            const matchesNachname = nachnameFilter === '' || (client.nachname && client.nachname.toLowerCase().includes(nachnameFilter.toLowerCase()));
            const { startDate, endDate } = getMonthRange(monthFilter);
            const sentDate = new Date(client.submite_date || "");
            const matchesDateRange = !monthFilter || (sentDate >= startDate && sentDate <= endDate);
            const matchesEmployee= employeeFilter===''||(client.employee_id && client.employee_id === employeeFilter);
            return matchesKunde && matchesNachname && matchesDateRange && matchesEmployee;
        });
    };

    const toggleSortOrder = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };
    const handleDelete = async () => {
        if (clientToDelete) {
            try {
                await deleteClient(clientToDelete);
                setClients(clients.filter(client => client.id !== clientToDelete));
                setConfirmationModalVisible(false);  // Close modal after deletion
            } catch (err) {
                setError('Failed to delete client. Please try again later.');
            }
        }
    };

    // Toggle confirmation modal visibility
    const showDeleteConfirmation = (clientId) => {
        setClientToDelete(clientId);
        setConfirmationModalVisible(true);
    };

    const hideDeleteConfirmation = () => {
        setConfirmationModalVisible(false);
        setClientToDelete(null);
    };

    const sortAntrags = (clients) => {
        const sortedAntrags = [...clients].sort((a, b) => {
            const dateA = new Date(a[sortField] || "");
            const dateB = new Date(b[sortField] || "");
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
        return sortedAntrags;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const filteredClients = getFilteredAntrags(clients);
    const sortedClients = sortAntrags(filteredClients);

    return (
        <div className='famielien-form-container'>
            <div className="my-antrags-container">
                <h2>Alle Kunden</h2>
                {clients.length === 0 ? (
                    <p>No clients found.</p>
                ) : (
                    
                    <div>
                       {(role === 'admin' || role === 'superAdmin') && (
    <div className="counter-wrapper">
        {/* Display the animated Aufträge count */}
        <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
            Num of Aufträge: {displayedCount}
        </p>

        {/* Arrow between the two counters */}
        <FaArrowRight className="arrow" />

        {/* Display the animated Provision count */}
        <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
            Provision: {money} €
        </p>
    </div>
)}


                        {/* Filter Inputs and Sorting */}
                        <div className="filter-card">
                            <div className="filters">
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Kunden Name"
                                    value={kundeFilter}
                                    onChange={(e) => setKundeFilter(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Nachname"
                                    value={nachnameFilter}
                                    onChange={(e) => setNachnameFilter(e.target.value)}
                                />
                                <input
                                    type="month"
                                    className="filter-input"
                                    value={monthFilter}
                                    onChange={(e) => setMonthFilter(e.target.value)}
                                />
  {/* Employee Filter */}
  <select
                                    className="filter-input"
                                    value={employeeFilter}
                                    onChange={(e) => setEmployeeFilter(e.target.value)}
                                >
                                    <option value="">Filter by Employee</option>
                                    {employees.map((employee) => (
                                        <option key={employee.id} value={employee.id}>
                                            {employee.username.split("@")[0]}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>

                        <div className='table-wrapper1'>
                            <table className="antrags-table">
                                <thead>
                                    <tr>
                                        <th>Vorname</th>
                                        <th>Nachname</th>
                                        <th>Geburtstag</th>
                                        <th>start datum</th>
                                        <th>Kinder</th>
                                        <th>Aufnamen</th>
                                        <th>Mobile</th>
                                        {
                                                role=='service' &&
                                        <th>Vermittler</th>}
                                      
                                        <th>Firma</th>
                                        <th onClick={() => toggleSortOrder('submite_date')}>
                                            Sent Date {sortField === 'submite_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                                        </th>
                                        { role=='admin' && 
                                        <th>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedClients.map((client) => {
                                        const { totalChildren, childrenOver15, totalAuf } = getChildInfo(client);
                                        return (
                                            <tr key={client.id} onClick={() =>  window.open(`/client-details/${client.id}`, '_blank')}>
                                                
                                                <td>{client.vornname}</td>
                                                <td>{client.nachname}</td>
                                                <td>{client.geburtstage ? new Date(client.geburtstage).toLocaleDateString() : 'N/A'}</td>
                                                <td>{client.start_date ? new Date(client.start_date).toLocaleDateString() : 'N/A'}</td>
                                                <td>{totalChildren}</td>
                                                <td>{totalAuf}</td>
                                                <td>{client.number}</td>
                                                {
                                                role=='service' &&
                                                <td className='emp_field'>{getName(client.employee_id)}</td>}
                                                <td>{client.firma}</td>
                                                <td>{client.submite_date ? new Date(client.submite_date).toLocaleDateString() : 'N/A'}</td>
                                                
                                                { role=='admin' && 
                                                <td>
                                                    <button className='delete' onClick={(e) => {
                                                        e.stopPropagation(); // Prevent row click
                                                        showDeleteConfirmation(client.id);
                                                    }}>Delete</button>
                                                </td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
             {/* Confirmation Modal */}
             {confirmationModalVisible && (
                <div className="confirmation-modal">
                    <div className="modal-content">
                        <h3>Are you sure you want to delete this client?</h3>
                        <div className="modal-buttons">
                            <button onClick={handleDelete} className="confirm-button">Yes, Delete</button>
                            <button onClick={hideDeleteConfirmation} className="cancel-button">Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllClients;
