import React, { useRef, useState, useEffect } from 'react';
import './BigBonus.css'; // Importing the new CSS file
import { fillBigBonusPdf,getAllEmployees,getClientsByEmployeeId } from '../../services/api';
import CustomCheckbox from '../CustomCheckbox';


const BigBonus = () => {

  const [haupt, setHaupt] = useState(false);

  const hadleHaupt=(e)=>{
    setHaupt(e.target.value)
  }

  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]); // Suggested clients
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState('');
  const [employee_Id, setEmployeeId] = useState("");
  useEffect(() => {
    const fetchEmployees = async () => {
        try {
            const response = await getAllEmployees();
            
            setEmployees(response.data); // Set employees in state
        } catch (err) {
            console.error('Error fetching employees:', err);
            setError('Failed to fetch employees.'); // Set error state
        }
    };
    fetchEmployees(); // Call fetch function
}, []); // Run on mount only

const handleEmployeeSelect = async (e) => {
  const employeeId = e.target.value;
  setFormData((prevData) => ({ ...prevData, employeeId }));
setClients([]);
  if (employeeId) {
    setEmployeeId(employeeId);
      const response = await getClientsByEmployeeId(employeeId);
     setClients(response.data);
  }
};

const handleNameChange = (e) => {
  const { name, type, value, checked } = e.target;
  setFormData({
    ...formData,
    [name]: type === 'checkbox' ? checked : value,
  });

  // Filter clients by matching name (case-insensitive) with check for client.name
  if (value) {
      const filteredOptions = clients.filter((client) =>
          client.nachname && client.nachname.toLowerCase().includes(value.toLowerCase())
      );
      
      setClientOptions(filteredOptions);
  } else {
      setClientOptions([]);
  }
};



const handleClientSelect = (client) => {
  console.log('haupt is : ', haupt);
  
  if (haupt==='true') {
    setFormData((prevData) => ({
      ...prevData,
      hauptname:client.nachname,
      Vorname: client.vornname,
      Name: client.nachname,
     
      clientId: client.id,
      
      employee_Id: client.employee_id,
    }));

    
  } else{
    setFormData((prevData) =>({
      ...prevData,
      hauptname:client.nachname,
      Vorname:'',
      Name:'',
    
     
      clientId: client.id,
      employee_Id: client.employee_id,
    }))
  }
  setClientOptions([]); // Clear the options only if haupt is true
};


    const [formData, setFormData] = useState({
      hauptname:'',
      clientId: '',
      employee_Id:'',
        Name: '',
        Vorname: '',
        Straße: '',
        Hausnummer: '',
        PLZ: '',
        Ort: '',
        Kontoinhaberin: '',
        Kreditinstitut: '',
        Ibn: '',
        BIC: '',
        Ort_2: '',
        Datum: '',
        UnterschriftKontoinhaberin: '',
        Euro: '',
        NameVorname: '',
        HoeheDerPolice: '',
        NameVorname2: '',
        HoeheDerPolice2: '',
        NameVorname3: '',
        HoeheDerPolice3: '',
        Ort_3: 'Essen',
        Datum2: '',
        StempelUnterschriftVermittlerin: 'Sham Direkt',
    
        // Checkboxes
        männlich: false,
        weiblich: false,
        divers: false,
        Neuabschluss: false,
        bestehendeZusatzversicherung: false,
        privateZusatzversicherung: false,
        Berufsunfähigkeitsversicherung: false,
        Unfallversicherung: false,
        Grundfähigkeitsversicherung: false,
        BIGforfit: false,
      });

      const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
      };


      const handleSubmit = async (e) => {

        // Get the signature as a data URL
   
        // Prepare form data with the signature

        e.preventDefault();
        try {
          const pdfBlob1=await fillBigBonusPdf(formData);
          //alert('Form submitted successfully!');
          const url = window.URL.createObjectURL(new Blob([pdfBlob1], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${formData.Name}-Bonus.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('There was an error submitting the form.');
        }
      };

      return (
        <div className='famielien-form-container'>
        <div className="bonus-form-container">
          <div className='person1'>
          <h1 className='head-Big'>Bonus- Big Direkt</h1>
          <div className='margin-bot'>
          
          <div className='personal-info2'>
          <div className='personal-info1' >
                      <label htmlFor="employeeId">Mitarbeiter auswahlen:</label>
                      <select 
                          id="employeeId" 
                          name="employeeId" 
                          value={formData.employeeId} 
                          onChange={handleEmployeeSelect}
                          required
                      >
                          <option value="" disabled>Mitarbeiter auswahlen</option>
                          {employees.map((employee) => (
                              <option key={employee.id} value={employee.id} style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                                  {employee.username.split("@")[0]} {/* Display employee name */}
                              </option>
                          ))}
                      </select>
  
                    
                    
              
              <label>is Haupt:</label>
                  <label>
                      <input
                      className='radio'
                          type="radio"
                          name="haupt"
                          value={true}
                        
                          onChange={hadleHaupt}
                      />
                      - yes
                  </label>
                  <label>
                      <input
                      className='radio'
                          type="radio"
                          name="haupt"
                          value={false}
                          
                          onChange={hadleHaupt}
                      />
                     - No
                  </label>
                  </div></div>
                  {clientOptions.length > 0 && (
                      <ul className="client-options">
                          {clientOptions.map((client) => (
                              <li key={client.id} onClick={() => handleClientSelect(client)}>
                                  {client.nachname} - {client.geburtstage}
                              </li>
                          ))}
                      </ul>
                  )}
            
            <div className="personal-info">
              <input
              name="hauptname"
              value={formData.hauptname}
              onInput={handleNameChange}
              placeholder="Haupt Kunde"
              type="text"
             
  
              className="form-input"
            />
            
             </div></div>
          <form onSubmit={handleSubmit} className="new-form">
            
    
            {/* Personal Information */}
            <h2 className="form-section-header">Persönliche Angaben</h2>
            
            <div className="form-group">
            <h3 className="form-section-header">Gender</h3>
            
        
              {/* Gender Checkboxes */}

        <div className="checkbox-group">
            <CustomCheckbox
              name="männlich"
              checked={formData.männlich}
              onChange={handleChange}
              label="männlich"
            />

         
            <CustomCheckbox
            label="weiblich"
              name="weiblich"
              checked={formData.weiblich}
              onChange={handleChange}
              className="form-checkbox"
            />
    
          
            <CustomCheckbox
            label="divers"
              name="divers"
              checked={formData.divers}
              onChange={handleChange}
              className="form-checkbox"
            />
          
  
        </div>
        <h3 className="form-section-header">Namen & Address</h3>
        <div className='personal-info'>
        <input
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            type="text"
            className="form-input"
          
          />
        <input
            name="Vorname"
            value={formData.Vorname}
            onChange={handleChange}
            placeholder="Vorname"
            type="text"
            className="form-input"
          
          />

        </div>

        <div className='personal-info'>
          <input
            name="Straße"
            value={formData.Straße}
            onChange={handleChange}
            placeholder="Straße"
            type="text"
            className="form-input"
          
          />
          <input
            name="Hausnummer"
            value={formData.Hausnummer}
            onChange={handleChange}
            placeholder="Hausnummer"
            type="text"
            className="form-input"
          
          />
       
          <input
            name="PLZ"
            value={formData.PLZ}
            onChange={handleChange}
            placeholder="PLZ"
            type="text"
            className="form-input"
          
          />
          <input
            name="Ort"
            value={formData.Ort}
            onChange={handleChange}
            placeholder="Ort"
            type="text"
            className="form-input"
          
          />
        </div>
        

             
              </div>
              <h2 className="form-section-header">Zahlungsempfänger*in</h2>
            
            <div className="form-group">
            <div className='personal-info'>
          <input
            name="Kontoinhaberin"
            value={formData.Kontoinhaberin}
            onChange={handleChange}
            placeholder="Kontoinhaberin"
            type="text"
            className="form-input"
          
          />
          <input
            name="Ort_2"
            value={formData.Ort_2}
            onChange={handleChange}
            placeholder="Ort"
            type="text"
            className="form-input"
          
          />
          <input
            name="Datum"
            value={formData.Datum}
            onChange={handleChange}
            placeholder="Datum"
            type="text"
            className="form-input"
          
          />

          
          </div>
          <div className='personal-info'>
          <input
            name="Kreditinstitut"
            value={formData.Kreditinstitut}
            onChange={handleChange}
            placeholder="Kreditinstitut"
            type="text"
            className="form-input"
          
          />
            <input
            name="Ibn"
            value={formData.Ibn}
            onChange={handleChange}
            placeholder="IBAN"
            type="text"
            className="form-input"
          
          />
          </div>
          <h2 className="form-section-header">Bestätigung Vermittler*in</h2>
          <div className="form-group">
           
            <div className="checkbox-group">
            <CustomCheckbox
              name="Neuabschluss"
              checked={formData.Neuabschluss}
              onChange={handleChange}
              label="Neuabschluss"
            />
            oder 
            <CustomCheckbox
              name="bestehendeZusatzversicherung"
              checked={formData.bestehendeZusatzversicherung}
              onChange={handleChange}
              label="bestehende Zusatzversicherung"
            />
</div>
<div className='personal-info'>
<h4 className=''>Höhe der jährlichen Police</h4>
<input
            name="Euro"
            value={formData.Euro}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          
          />
          </div>
          <div className="checkbox-group">
            <CustomCheckbox
              name="privateZusatzversicherung"
              checked={formData.privateZusatzversicherung}
              onChange={handleChange}
              label="privateZusatzversicherung"
            />
  
            <CustomCheckbox
              name="Berufsunfähigkeitsversicherung"
              checked={formData.Berufsunfähigkeitsversicherung}
              onChange={handleChange}
              label="Berufsunfaehigkeitsversicherung"
            />
            <CustomCheckbox
              name="Unfallversicherung"
              checked={formData.Unfallversicherung}
              onChange={handleChange}
              label="Unfallversicherung"
            />
            <CustomCheckbox
              name="Grundfähigkeitsversicherung"
              checked={formData.Grundfähigkeitsversicherung}
              onChange={handleChange}
              label="Grundfaehigkeitsversicherung"
            />
</div>
<h4 className=''>Gilt auch für folgende mitversicherte Angehörige:</h4>
<div className='personal-info'>

<input
            name="NameVorname"
            value={formData.NameVorname}
            onChange={handleChange}
            placeholder="NameVorname"
            type="text"
            className="form-input"
          
          />
<input
            name="HoeheDerPolice"
            value={formData.HoeheDerPolice}
            onChange={handleChange}
            placeholder="HoeheDerPolice"
            type="text"
            className="form-input"
          
          />

          </div>
<div className='personal-info'>

<input
            name="NameVorname2"
            value={formData.NameVorname2}
            onChange={handleChange}
            placeholder="NameVorname"
            type="text"
            className="form-input"
          
          />
<input
            name="HoeheDerPolice2"
            value={formData.HoeheDerPolice2}
            onChange={handleChange}
            placeholder="HoeheDerPolice"
            type="text"
            className="form-input"
          
          />

          </div>
<div className='personal-info'>

<input
            name="NameVorname3"
            value={formData.NameVorname3}
            onChange={handleChange}
            placeholder="NameVorname"
            type="text"
            className="form-input"
          
          />
<input
            name="HoeheDerPolice3"
            value={formData.HoeheDerPolice3}
            onChange={handleChange}
            placeholder="HoeheDerPolice"
            type="text"
            className="form-input"
          
          />

          </div>

<div className="checkbox-group">
<CustomCheckbox
              name="BIGforfit"
              checked={formData.BIGforfit}
              onChange={handleChange}
              label="BIGforfit App Registrierung erfolgt"
            />
</div>
<input
            name="Datum2"
            value={formData.Datum2}
            onChange={handleChange}
            placeholder="Datum"
            type="text"
            className="form-input"
          
          />
</div>
        
              {/* Gender Checkboxes */}

        </div>
        <button type="submit" className="submit-button">Submit</button>
              </form>
              </div></div>
              </div>);

}

export default BigBonus;
