// src/components/CustomCheckbox.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'; // For unchecked
import { faCheck } from '@fortawesome/free-solid-svg-icons'; // For checked
import './CustomCheckbox.css'; // You can create a separate CSS file for styling

const CustomCheckbox = ({ label, checked, onChange, name }) => {
  return (
    <label className="custom-checkbox-label">
      {label}:
      <input 
        type="checkbox" 
        name={name} 
        checked={checked} 
        onChange={onChange} 
        className="custom-checkbox" 
      />
      <span className="checkbox-icon">
        <FontAwesomeIcon icon={checked ? faCheck : faSquare} />
      </span>
    </label>
  );
};

export default CustomCheckbox;
