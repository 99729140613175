import React, { useRef, useState, useEffect } from 'react';
import'./BigFamielien.css'
import { fillBigSecondPdf,fillBigFamielienPdf,getAllEmployees, searchClientByFirstName ,getClientsByEmployeeId } from '../../services/api';
import CustomCheckbox from '../CustomCheckbox';
import SignaturePad from 'signature_pad';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing icons
import ChildInfo from './Kindinfo';
import ChildMain from './KindMain';
import ChildInfo3 from './Kindinfo3';

const BigFamielien=()=>{

  const [employees, setEmployees] = useState([]);
  const [haupt, setHaupt] = useState(false);
  const [employee_Id, setEmployeeId] = useState("");
  const [isVisible, setIsVisible] = useState([false,false,false,false,false,false,false]); // State to control visibility
  const [error, setError] = useState('');
  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]); // Suggested clients
  useEffect(() => {
    const fetchEmployees = async () => {
        try {
            const response = await getAllEmployees();
            
            setEmployees(response.data); // Set employees in state
        } catch (err) {
            console.error('Error fetching employees:', err);
            setError('Failed to fetch employees.'); // Set error state
        }
    };
    fetchEmployees(); // Call fetch function
}, []); // Run on mount only

const handleEmployeeSelect = async (e) => {
  const employeeId = e.target.value;
  setFormData((prevData) => ({ ...prevData, employeeId }));
setClients([]);
  if (employeeId) {
    setEmployeeId(employeeId);
      const response = await getClientsByEmployeeId(employeeId);
     setClients(response.data);
  }
};

const handleNameChange = (e) => {
  const { name, type, value, checked } = e.target;
  setFormData({
    ...formData,
    [name]: type === 'checkbox' ? checked : value,
  });

  // Filter clients by matching name (case-insensitive) with check for client.name
  if (value) {
      const filteredOptions = clients.filter((client) =>
          client.nachname && client.nachname.toLowerCase().includes(value.toLowerCase())
      );
      
      setClientOptions(filteredOptions);
  } else {
      setClientOptions([]);
  }
};

const handleClientSelect = (client) => {
  console.log('haupt is : ', haupt);
  
  if (haupt==='true') {
    setFormData((prevData) => ({
      ...prevData,
      hauptname:client.nachname,
      Vorname: client.vornname,
      Name: client.nachname,
      mobile: client.number,
      clientId: client.id,
      geburtstage: client.geburtstage,
      employee_Id: client.employee_id,
    }));

    
  } else{
    setFormData((prevData) =>({
      ...prevData,
      hauptname:client.nachname,
      Vorname:'',
      Name:'',
      mobile:'',
      geburtstage: '',
      clientId: client.id,
      employee_Id: client.employee_id,
    }))
  }
  setClientOptions([]); // Clear the options only if haupt is true
};


const signaturePadRef = useRef(null); // Ref for SignaturePad
const canvasRef = useRef(null); // Ref for the canvas element

// Initialize the signature pad once the canvas is ready
useEffect(() => {
  if (canvasRef.current && !signaturePadRef.current) {
    signaturePadRef.current = new SignaturePad(canvasRef.current);
  }
}, [canvasRef]);

// Clear the signature pad
const handleClearSignature = () => {
  if (signaturePadRef.current) {
    signaturePadRef.current.clear();
  }
};

 
  // Toggle visibility function
  const handleSelectChange = (e) => {
    const index = parseInt(e.target.value);
    setFormData({
      ...formData,
      kindNum:index,
    });
    const updatedVisibility = isVisible.map((_, i) => i <= index);
    setIsVisible(updatedVisibility);
  };

    const [formData, setFormData] = useState({

      geburtstage:'',
      kindNum:0,
      employee_Id:'',
      clientId:'',
      signature: '',
        Name: "",/*ok*/
        Vorname: "",/*ok*/
        Versichertennummer: "",/*ok*/
        Vorwahl: "+49",/*ok*/
        mobile: "",/*ok*/
        email: "",
        familienStandseit: "",/*ok*/
        Name_Krankenkasse: "",/*ok*/
        sonistiges: "",/*ok*/
        Datum_partner_begin: "",
        partner_name: "",
        partner_vorname: "",
        partner_Geburtsdatum: "",
        partner_Address:'',

        Datum_kind1_begin: "",
        kind1_name: "",
        kind1_vorname: "",
        kind1_Geburtsdatum: "",
        kind1_Address:'',

        Datum_kind2_begin: "",
        kind2_name: "",
        kind2_vorname: "",
        kind2_Geburtsdatum: "",
        kind2_Address:'',

        Datum_kind3_begin: "",
        kind3_name: "",
        kind3_vorname: "",
        kind3_Geburtsdatum: "",
        kind3_Address:'',

        Datum_kind4_begin: "",
        kind4_name: "",
        kind4_vorname: "",
        kind4_Geburtsdatum: "",
        kind4_Address:'',

        Datum_kind5_begin: "",
        kind5_name: "",
        kind5_vorname: "",
        kind5_Geburtsdatum: "",
        kind5_Address:'',

        Datum_kind6_begin: "",
        kind6_name: "",
        kind6_vorname: "",
        kind6_Geburtsdatum: "",
        kind6_Address:'',

        partner_oldVerEnd: "",
        partner_oldVerName: "",
        partner_otherVerName: "",
        partner_NeuVerName: "",

        kind1_oldVerEnd: "",
        kind1_oldVerName: "",
        kind1_otherVerName: "",
        kind1_NeuVerName: "",

        kind2_oldVerEnd: "",
        kind2_oldVerName: "",
        kind2_otherVerName: "",
        kind2_NeuVerName: "",

        kind3_oldVerEnd: "",
        kind3_oldVerName: "",
        kind3_otherVerName: "",
        kind3_NeuVerName: "",

        kind4_oldVerEnd: "",
        kind4_oldVerName: "",
        kind4_otherVerName: "",
        kind4_NeuVerName: "",

        kind5_oldVerEnd: "",
        kind5_oldVerName: "",
        kind5_otherVerName: "",
        kind5_NeuVerName: "",

        kind6_oldVerEnd: "",
        kind6_oldVerName: "",
        kind6_otherVerName: "",
        kind6_NeuVerName: "",



        partner_Versichertennummer: "",
        partner_Rentenversicherungsnummer: "",
        partner_Geburtsname: "",
        partner_ort: "",
        partner_geburtsland: "",
        partner_staatsangehorigkeit: "",

        kind1_Versichertennummer: "",
        kind1_Rentenversicherungsnummer: "",
        kind1_Geburtsname: "",
        kind1_ort: "",
        kind1_geburtsland: "",
        kind1_staatsangehorigkeit: "",
    
        kind2_Versichertennummer: "",
        kind2_Rentenversicherungsnummer: "",
        kind2_Geburtsname: "",
        kind2_ort: "",
        kind2_geburtsland: "",
        kind2_staatsangehorigkeit: "",

     
        kind3_Versichertennummer: "",
        kind3_Rentenversicherungsnummer: "",
        kind3_Geburtsname: "",
        kind3_ort: "",
        kind3_geburtsland: "",
        kind3_staatsangehorigkeit: "",

        
        kind4_Versichertennummer: "",
        kind4_Rentenversicherungsnummer: "",
        kind4_Geburtsname: "",
        kind4_ort: "",
        kind4_geburtsland: "",
        kind4_staatsangehorigkeit: "",

        kind5_Versichertennummer: "",
        kind5_Rentenversicherungsnummer: "",
        kind5_Geburtsname: "",
        kind5_ort: "",
        kind5_geburtsland: "",
        kind5_staatsangehorigkeit: "",

        kind6_Versichertennummer: "",
        kind6_Rentenversicherungsnummer: "",
        kind6_Geburtsname: "",
        kind6_ort: "",
        kind6_geburtsland: "",
        kind6_staatsangehorigkeit: "",


        ort:"",
        datum:"",
        hauptname:'',
        // Add similar data for kind1, kind2, kind3 as needed
        Anlass: "",/*ok*/
        Verwandtschaft_Kind1: "",
        Verwandtschaft_Kind2: "",
        Verwandtschaft_Kind3: "",
        BIsherige_Vers_Partner: "",
        BIsherige_Vers_kind1: "",
        BIsherige_Vers_kind2: "",
        BIsherige_Vers_kind3: "",
        Versicherung76: "",/*ok*/
        Familienstand: "",/*ok*/
        Erreichbarkeit: "",
        Geschlecht_Lebenspartner: "",
        Geschlecht_Lebenspartner_1: "",
        Geschlecht_Lebenspartner_2: "",
        Geschlecht_Lebenspartner_3: "",
        // Checkbox example fields
        Kontrollkästchen_093: false,
        Kontrollkästchen_101: false,
        Kontrollkästchen_109: false,
        Kontrollkästchen_132: false,
        Kontrollkästchen_158: false,
        Kontrollkästchen_171: false,
        Kontrollkästchen_151: false,
        Kontrollkästchen_164: false,
        Kontrollkästchen_177: false,
        Kontrollkästchen_4:false,
        Kontrollkästchen_5:false,
        Kontrollkästchen_6:false,
        ch146:false,
        ch139:false,
        haupt:false
      });
    

      const [showOptions, setShowOptions] = useState(false); // Controls visibility of options list

       // Function to handle name input change and search for clients
  const handleNameBlur = async (e) => {
    const Name = e.target.value;
    setFormData((prevData) => ({ ...prevData, Name }));

    if (Name.length > 0) {
      try {
        const clients = await searchClientByFirstName(Name);
        if (clients.length > 0) {
          setClientOptions(clients); // Set matching clients
          setShowOptions(true); // Show options list
        } else {
          setClientOptions([]); // No matches
          setShowOptions(false);
        }
      } catch (error) {
        console.error('Error searching for clients:', error);
      }
    }
  };


      const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
      };

      const downloadSecondFile=async(e)=>{
        if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) {
          alert('Please provide a signature.');
          return;
        }

        const signatureDataURL = signaturePadRef.current.toDataURL();
        const updatedFormData = {
          ...formData,
          signature: signatureDataURL,
        };
        e.preventDefault();
        try {
          const pdfBlob1=await fillBigSecondPdf(updatedFormData);
          //alert('Form submitted successfully!');
          const url = window.URL.createObjectURL(new Blob([pdfBlob1], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${formData.Name}-Familien2.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('There was an error submitting the form.');
        }
      };

      const handleSubmit = async (e) => {

           // Ensure the signature pad is not empty
    if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) {
      alert('Please provide a signature.');
      return;
    }

    // Get the signature as a data URL
    const signatureDataURL = signaturePadRef.current.toDataURL();

    // Prepare form data with the signature
    const updatedFormData = {
      ...formData,
      signature: signatureDataURL,
    };
        // Get the signature as a data URL
   
        // Prepare form data with the signature

        e.preventDefault();
        try {
          const pdfBlob1=await fillBigFamielienPdf(updatedFormData);
          //alert('Form submitted successfully!');
          const url = window.URL.createObjectURL(new Blob([pdfBlob1], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${formData.Name}-Familien1.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('There was an error submitting the form.');
        }
      };

       // Close dropdown if clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest('.name-input-container')) {
          setShowOptions(false);
      }
  };
  const hadleHaupt=(e)=>{
    setHaupt(e.target.value)
  }

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

      return(<div className='famielien-form-container'>
      
       <h1 className='head-Big'>Famielien- Big Direkt</h1>
       
      
       <form onSubmit={handleSubmit} className="new-form">
        <div className='person1'>
       <div className="form-group">
       <h2 className="form-section-header1">Persönliche Angaben des Mitglieds</h2>
       <div className='personal-info1'>
        <div>
                    <label htmlFor="employeeId">Mitarbeiter auswahlen:</label>
                    <select 
                        id="employeeId" 
                        name="employeeId" 
                        value={formData.employeeId} 
                        onChange={handleEmployeeSelect}
                        required
                    >
                        <option value="" disabled>Mitarbeiter auswahlen</option>
                        {employees.map((employee) => (
                            <option key={employee.id} value={employee.id} style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                                {employee.username.split("@")[0]} {/* Display employee name */}
                            </option>
                        ))}
                    </select>
                    </div>
                    
                    
            <div className="personal-info">
            <label>is Haupt:</label>
            <div>
                <label>
                    <input
                    className='radio'
                        type="radio"
                        name="haupt"
                        value={true}
                      
                        onChange={hadleHaupt}
                    />
                    - yes
                </label>
                <label>
                    <input
                    className='radio'
                        type="radio"
                        name="haupt"
                        value={false}
                        
                        onChange={hadleHaupt}
                    />
                   - No
                </label>
                </div>
            </div>  </div>
       <div className='personal-info1'>
       <input
            name="hauptname"
            value={formData.hauptname}
            onInput={handleNameChange}
            placeholder="Haupt Kunde"
            type="text"
           

            className="form-input"
          />

{clientOptions.length > 0 && (
                    <ul className="client-options">
                        {clientOptions.map((client) => (
                            <li key={client.id} onClick={() => handleClientSelect(client)}>
                                {client.nachname} - {client.geburtstage}
                            </li>
                        ))}
                    </ul>
                )}
       <input
            name="Vorname"
            value={formData.Vorname}
            onChange={handleChange}
            placeholder="Vorname"
            type="text"
            className="form-input"
          />
       <input
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            type="text"
            className="form-input"
          />
      { /*<input
            name="Versichertennummer"
            value={formData.Versichertennummer}
            onChange={handleChange}
            placeholder="Versichertennummer"
            type="text"
            className="form-input"
          />*/}
         
          </div>
          { /*  <div className='personal-info'>
       <input
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="mobile"
            type="text"
            className="form-input"
          /><input
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          type="text"
          className="form-input"
        /></div>*/}
        
          <div className="personal-info1">
          <div className="person">
       

  </div>
  
          <div className="personal-info1">
            <div className='person1'>
            <div className="personal-info1">
            <label className='label-Big'>Famielienstand:</label>
  <div className="select-dropdown">
    <select 
      name="Familienstand" 
      value={formData.Familienstand} 
      onChange={handleChange}
    >
      <option value="Auswahl1">ledig</option>
      <option value="Auswahl2">verheiratet</option>
      <option value="3">verwitwet</option>
      <option value="5">geschieden</option>
    </select>
  </div><div></div>
  <label className='label-Big'>Ich war bisher:</label>
  <div className="select-dropdown">
    
    <select 
      name="Versicherung76" 
      value={formData.Versicherung76} 
      onChange={handleChange}
    >
      <option value="Auswahl1">selbst versichert</option>
      <option value="Auswahl2">familienversichert</option>
      <option value="2">nicht gesetzlich krankenversichert</option>
    </select>
    
  </div>
  </div>
  <div className="personal-info1">
  <p>
      Name der Krankenkasse
      </p>
      <input
            name="Name_Krankenkasse"
            value={formData.Name_Krankenkasse}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          
          </div>
          <div className="personal-info1">
  <p>Anlass für die Aufnahme in die Familienversicherung:</p>
  <div className="select-dropdown">
    <select 
      name="Anlass" 
      value={formData.Anlass} 
      onChange={handleChange}
    >
      <option value="Auswahl1">Beginn meiner Mitgliedschaft</option>
      <option value="3">Heirat</option>
      <option value="2">Geburt des Kindes</option>
      <option value="4">Beendigung der vorherigen Mitgliedschaft</option>
      <option value="Auswahl2">Sonstiges</option>
    </select>
  </div></div>
  </div>
</div>
</div>

     


      <div className='personal-info1'>
    
    
         <div className="person">
       
  { /*
  <label className='label-Big'>
      sonistiges
      <input
            name="sonistiges"
            value={formData.sonistiges}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>*/}
  
</div>

      </div>
      
          </div>
      
          </div>
          <h4>Bitte Kinderzahl angeben.</h4>
       <div className="select-dropdown">
    <select
      name="num-child"
      onChange={handleSelectChange}
    >
      <option value="0">0 Kinder</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
     
    </select>
  </div>
          <div className="form-group">
          <h2 className="form-section-header1">Angaben zu Familienangehörigen</h2>
          <div className='personal-info1'>
          <div className='person'>
            <h3 className="form-section-header">Lebenspartner</h3>
        
          <label className='label-Big'>
          Beginn der Familienversicherung (TT/MM/JJ)
      <input
            name="Datum_partner_begin"
            value={formData.Datum_partner_begin}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <div className='namen'>
          <label className='label-Big'>
          Partner Name
      <input
            name="partner_name"
            value={formData.partner_name}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          Partner VorName
      <input
            name="partner_vorname"
            value={formData.partner_vorname}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          </div>
          <div className='namen'>
         
          <div className="select-dropdown">
          
    <select 
      name="Geschlecht_Lebenspartner" 
      value={formData.Geschlecht_Lebenspartner} 
      onChange={handleChange}
    >
      <option value="">Geschlecht</option>
      <option value="Auswahl1">M</option>
      <option value="3">W</option>
    </select>
  </div>
        
      <label className='label-Big'>
          Geburts Datum
      <input
            name="partner_Geburtsdatum"
            value={formData.partner_Geburtsdatum}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          </div>
      <label className='label-Big'>
          Anschrift
      <input
            name="partner_Address"
            value={formData.partner_Address}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          

          </div>
          </div>
          <div className='personal-info'>
          
      
          {isVisible[1] &&(
       <ChildMain
       title="erste Kind"
       formData={formData}
       handleChange={handleChange}
       namePrefix="1"
       Kontrollkästchen="Kontrollkästchen_093"
     />
        )}
          {isVisible[2] &&(<ChildMain
        title="zweite Kind"
        formData={formData}
        handleChange={handleChange}
        namePrefix="2"
        Kontrollkästchen="Kontrollkästchen_101"
      />
            
         )}
            { isVisible[3]&&(
        <ChildMain
        title="Dritte Kind"
        formData={formData}
        handleChange={handleChange}
        namePrefix="3"
        Kontrollkästchen="Kontrollkästchen_109"
      />)}
          </div>
          <div className='personal-info'>
          
      
          {isVisible[4] &&(
       <ChildMain
       title="4TH Kind"
       formData={formData}
       handleChange={handleChange}
       namePrefix="4"
       Kontrollkästchen="Kontrollkästchen_4"
     />
        )}
          {isVisible[5] &&(<ChildMain
        title="5TH Kind"
        formData={formData}
        handleChange={handleChange}
        namePrefix="5"
        Kontrollkästchen="Kontrollkästchen_5"
      />
            
         )}
            { isVisible[6]&&(
        <ChildMain
        title="6TH Kind"
        formData={formData}
        handleChange={handleChange}
        namePrefix="6"
        Kontrollkästchen="Kontrollkästchen_6"
      />)}
          </div>

          {/*2+3 kind */}

          
          <div className='personal-info'>
         
        
          </div> 
          </div>
          <div className="form-group">
          <h2 className="form-section-header1">Angaben zur letzten bisherigen oder<br/> zur weiter bestehenden Versicherung<br/> der Familienangehörigen</h2>
          <div className='personal-info1'>
          <div className='person'>
          <h3 className="form-section-header">Partner</h3>
          <div className='namen'>
          <label className='label-Big'>
          Die bisherige Versicherung endete am:(TT/MM/JJ)
      <input
            name="partner_oldVerEnd"
            value={formData.partner_oldVerEnd}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          Name der Krankenkasse     
           <input
            name="partner_oldVerName"
            value={formData.partner_oldVerName}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          </div>
          <div className='personal-info'>
          <div class="select-dropdown">
  <select name="BIsherige_Vers_Partner" value={formData.BIsherige_Vers_Partner} onChange={handleChange}>
    <option value="">Art der bisherigen Versicherung</option>
    <option value="Auswahl1">selbst</option>
    <option value="Auswahl2">familienversichert</option>
    <option value="2">nicht</option>
  </select>
</div> </div>
        
      <label className='label-Big'>
      Sofern zuletzt eine Familienversicherung bestand,
Name und Vorname der Person, aus deren Mitgliedschaft
die Familienversicherung abgeleitet wurde      <input
            name="partner_otherVerName"
            value={formData.partner_otherVerName}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          Die bisherige Versicherung besteht weiter bei      <input
            name="partner_NeuVerName"
            value={formData.partner_NeuVerName}
            onChange={handleChange}
            placeholder="Big ?"
            type="text"
            className="form-input"
          />
          </label>
          </div></div>
<div className='personal-info1'>
          {/* kind 1  */}
          {isVisible[1] &&(
          <ChildInfo
          title="Kind 1"
          formData={formData}
          handleChange={handleChange}
          namePrefix="kind1"
        />)}
          

         
              {/* kind 1  */}
              {isVisible[2] &&(<ChildInfo
      title="Kind 2"
      formData={formData}
      handleChange={handleChange}
      namePrefix="kind2"
    />)}

          {/*3rd person*/}
          {isVisible[3] &&(
      <ChildInfo
      title="Kind 3"
      formData={formData}
      handleChange={handleChange}
      namePrefix="kind3"
    />)}
          </div>
          <div className='personal-info1'>
          {/* kind 1  */}
          {isVisible[4] &&(
          <ChildInfo
          title="Kind 4"
          formData={formData}
          handleChange={handleChange}
          namePrefix="kind4"
        />)}
          

         
              {/* kind 1  */}
              {isVisible[5] &&(<ChildInfo
      title="Kind 5"
      formData={formData}
      handleChange={handleChange}
      namePrefix="kind5"
    />)}

          {/*3rd person*/}
          {isVisible[6] &&(
      <ChildInfo
      title="Kind 6"
      formData={formData}
      handleChange={handleChange}
      namePrefix="kind6"
    />)}
          </div>
          </div>
          <div className="form-group">
          <h2 className="form-section-header1">Angaben zur Vergabe einer <br/>Krankenversichertennummer für <br/>familienversicherte Angehörige</h2>
          <div className='personal-info1'>
          <div className='person'>
          <h3 className="form-section-header">partner</h3>
<div className='namen'>
          <label className='label-Big'>
          Versichertennummer
          <input
            name="partner_Versichertennummer"
            value={formData.partner_Versichertennummer}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          Rentenversicherungsnummer
          <input
            name="partner_Rentenversicherungsnummer"
            value={formData.partner_Rentenversicherungsnummer}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          </div>
          <div className='namen'>
          <label className='label-Big'>
          Geburtsname
          <input
            name="partner_Geburtsname"
            value={formData.partner_Geburtsname}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          Geburtsort
          <input
            name="partner_ort"
            value={formData.partner_ort}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          
          </label>
          </div>
          <div className='namen'>
          <label className='label-Big'>
          geburtsland
          <input
            name="partner_geburtsland"
            value={formData.partner_geburtsland}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          <label className='label-Big'>
          staatsangehorigkeit
          <input
            name="partner_staatsangehorigkeit"
            value={formData.partner_staatsangehorigkeit}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
          </label>
          </div>
          </div></div><div className='personal-info1'>
          {isVisible[1] &&(
        <ChildInfo3
        title="Kind 1"
        formData={formData}
        handleChange={handleChange}
        namePrefix="kind1"
      />)}
         
          
          {isVisible[2] &&( <ChildInfo3
        title="Kind 2"
        formData={formData}
        handleChange={handleChange}
        namePrefix="kind2"
      />)}
            {isVisible[3] &&(
             <ChildInfo3
             title="Kind 3"
             formData={formData}
             handleChange={handleChange}
             namePrefix="kind3"
           />)}
          </div>
          <div className='personal-info1'>
          {isVisible[4] &&(
        <ChildInfo3
        title="Kind 4"
        formData={formData}
        handleChange={handleChange}
        namePrefix="kind4"
      />)}
         
          
          {isVisible[5] &&( <ChildInfo3
        title="Kind 5"
        formData={formData}
        handleChange={handleChange}
        namePrefix="kind5"
      />)}
            {isVisible[6] &&(
             <ChildInfo3
             title="Kind 6"
             formData={formData}
             handleChange={handleChange}
             namePrefix="kind6"
           />)}
          </div>
    </div>
    <div className='person'>
          <label>
            Ort
    <input 
    name='ort'
    value={formData.ort}
    onChange={handleChange}
    type='text'
    className='form-input'
    />
    </label>
    <label>
      Datum
    <input 
    name='datum'
    value={formData.datum}
    onChange={handleChange}
    type='date'
    className='form-input'
    />
    </label>
   
    </div>
    <h3>Signature</h3>
        <canvas ref={canvasRef} className="signature-pad" width="1000" height="200"></canvas>
        <button type="button" onClick={handleClearSignature} className="clear-button">
          Clear Signature
        </button>
  
    <button type="submit" className="submit-button">Submit</button>
    {isVisible[4] &&(<button type="button" className="submit-button" onClick={downloadSecondFile}>download the second group of kids</button>
    )}</form>

      </div>);


}
export default BigFamielien;



