import React, { useEffect, useState } from 'react';
import './Allantrags.css'; // Import your CSS styles
import { getAllAntrags, deleteAntrag } from '../services/api'; // Import the deleteAntrag function
import { FaArrowRight } from 'react-icons/fa'; // Importing the arrow icon

const AllAntrags = () => {
    const [antrags, setAntrags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayedCount, setDisplayedCount] = useState(0);
    const [money, setMoney] = useState(0);
    const [animateCount, setAnimateCount] = useState(false);

    // States for filtering
    const [employeeIdFilter, setEmployeeIdFilter] = useState('');
    const [monthFilter, setMonthFilter] = useState(''); // Month filter
    const [typeFilter, setTypeFilter] = useState('');

    // State for sorting
    const [sortField, setSortField] = useState('sent'); // Default sort field
    const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction

    useEffect(() => {
        // Fetching the existing Antrags for the current employee
        const fetchAntrags = async () => {
            try {
                const response = await getAllAntrags(); // Fetching the Antrags
                setAntrags(response.data); // Set the antrags from response
            } catch (error) {
                setError(error); // Set error state if there's an issue
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchAntrags();
    }, []);

    // Animate the count using a manual counter
    useEffect(() => {
        increaseAmount(antrags.length);
    }, [antrags]);

    const increaseAmount = (num) => {
        let startCount = 0;
        const endCount = num;

        if (endCount > 0) {
            const duration = 1000; // Animation duration in milliseconds
            const increment = Math.ceil(endCount / (duration / 1)); // Adjust increment based on duration

            const interval = setInterval(() => {
                startCount += increment;
                if (startCount >= endCount) {
                    startCount = endCount;
                    clearInterval(interval); // Stop when we reach the final count
                }
                setDisplayedCount(startCount); // Update the displayed count
                setMoney(startCount * 20); // Update money count based on Aufträge
                setAnimateCount(true); // Trigger animation
                setTimeout(() => setAnimateCount(false), 1000); // Reset animation state
            }, 100); // Run every 100 milliseconds

            return () => clearInterval(interval); // Clean up on unmount
        }
    };

    // Function to get the start and end dates for the selected month
    const getMonthRange = (month) => {
        if (!month) {
            return { startDate: new Date(0), endDate: new Date() }; // Default range
        }

        const date = new Date(month);
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1); // First day of the month
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of the month
        return { startDate, endDate };
    };

    // Filter function
    const filteredAntrags = antrags.filter(antrag => {
        const matchesEmployeeId = employeeIdFilter === '' || String(antrag.username.toLowerCase()).includes(employeeIdFilter.toLowerCase());
        const { startDate, endDate } = getMonthRange(monthFilter);
        const sentDate = new Date(antrag.sent);
        const matchesDateRange = !monthFilter || (sentDate >= startDate && sentDate <= endDate);
        const matchesType = typeFilter === '' || antrag.type_of_antrag.toLowerCase().includes(typeFilter.toLowerCase());
        return matchesEmployeeId && matchesDateRange && matchesType;
    });

    // Sorting function
    const sortedAntrags = filteredAntrags.sort((a, b) => {
        const aDate = new Date(a[sortField]);
        const bDate = new Date(b[sortField]);

        if (sortDirection === 'asc') {
            return aDate - bDate; // Ascending order
        } else {
            return bDate - aDate; // Descending order
        }
    });

    // Function to handle the delete action
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this Antrag?");
        if (confirmDelete) {
            try {
                await deleteAntrag(id); // API call to delete the antrag
                setAntrags(antrags.filter(antrag => antrag.id !== id)); // Update the UI by removing the deleted Antrag
            } catch (error) {
                console.error("Failed to delete Antrag", error);
                alert("Failed to delete Antrag. Please try again.");
            }
        }
    };

    // Function to toggle sorting
    const handleSort = (field) => {
        const direction = (sortField === field && sortDirection === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className='body'>
            <div className="all-antrags-container-a">
                <h2>Alle Aufträge</h2>
                {antrags.length === 0 ? (
                    <p>No Antrags found.</p>
                ) : (
                    <div>
                        {/* Container to hold both counters and arrow */}
                        <div className="counter-wrapper">
                            {/* Display the animated Aufträge count */}
                            <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
                                Num of Aufträge: {displayedCount}
                            </p>

                            {/* Arrow between the two counters */}
                            <FaArrowRight className="arrow" />

                            {/* Display the animated Provision count */}
                            <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
                                Provision: {money} €
                            </p>
                        </div>

                        <div className="filter-card">
                            <div className="filters">
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Employee Name"
                                    value={employeeIdFilter}
                                    onChange={(e) => setEmployeeIdFilter(e.target.value)}
                                />
                                <input
                                    type="month"
                                    className="filter-input"
                                    placeholder="Select Month"
                                    value={monthFilter}
                                    onChange={(e) => setMonthFilter(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="filter-input"
                                    placeholder="Filter by Type of Antrag"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="sorting-buttons">
                            <button onClick={() => handleSort('sent')} className="sort-button">
                                Sort by Sent Date {sortField === 'sent' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </button>
                            <button onClick={() => handleSort('end_of_antrag')} className="sort-button">
                                Sort by End of Auftrag {sortField === 'end_of_antrag' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </button>
                        </div>
                        {/* Table displaying antrags */}
                        <div className="table-wrapper">
                            <table className="antrags-table">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Ver-Num</th>
                                        <th>Kunde</th>
                                        <th>K-Nachname</th>
                                        <th>Geburtstag</th>
                                        <th>Auftrag Start</th>
                                        <th>

                                            Sent Date

                                        </th>
                                        <th>

                                            End of Auftrag

                                        </th>
                                        <th>Firma</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedAntrags.map((antrag) => (
                                        <tr key={antrag.id} className="table-row">
                                            <td>{antrag.username.split("@")[0]}</td>
                                            <td>{antrag.vernumer}</td>
                                            <td>{antrag.name}</td>
                                            <td>{antrag.nachname}</td>
                                            <td>{new Date(antrag.geburtstag).toLocaleDateString()}</td>
                                            <td>{new Date(antrag.antrag_start).toLocaleDateString()}</td>
                                            <td>{new Date(antrag.sent).toLocaleDateString()}</td>
                                            <td>{new Date(antrag.end_of_antrag).toLocaleDateString()}</td>
                                            <td>{antrag.type_of_antrag}</td>

                                            {/* Delete button inside Action */}
                                            <td>
                                                <button
                                                    onClick={() => handleDelete(antrag.id)}
                                                    className="delete-button"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllAntrags;
