// src/components/PdfForm.js

import { useState } from 'react';
import "./pdfForm.css"; // Your existing CSS for the form
import { fillPdf } from '../services/api'; // Your API service
import CustomCheckbox from './CustomCheckbox'; // Import the CustomCheckbox component
import SignPdf from './PdfSmsSign';

const PdfForm = () => {
  const [link, setLink] = useState('');

  const handleSendLink = async () => {
    const phoneNumber = '+491786391086'; // رقم WhatsApp للعميل
    const response = await fetch(`${process.env.REACT_APP_API1_URL}/twillo/send-link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phoneNumber }),
    });

    if (response.ok) {
      const data = await response.json();
      setLink(data.link); // تعيين الرابط المرسل إلى الحالة
    } else {
      alert('فشل في إرسال الرابط');
    }
  };


  const [formData, setFormData] = useState({
    Datum: '',
    Name: '',
    Vorname: '',
    Geburtsname: '',
    Geburtsort: '',
    Geburtsland: '',
    Geburtsdatum: '',
    Staatsangehörigkeit: '',
    weiblich: false,
    männlich: false,
    divers: false,
    Straße: '',
    Hausnummer: '',
    PLZ: '',
    Ort: '',
    Telefon: '',
    EMail: '',
    ledig: false,
    verheiratet_Familienstand: false,
    Lebenspartnersch: false,
    beschäftigt: false,
    Ausbildung: false,
    Rente: false,
    'bin freiwillig versichert': false,
    'ich studiere': false,
    'AL-Geld I': false,
    'AL-Geld II': false,
    Minijob: false,
    selbstständig: false,
    Jaeg: false,
    Name_des_Arbeitgebers: '',
    Straße_2: '',
    Hausnummer_2: '',
    PLZ_2: '',
    Ort_2: '',
    Beschäftigt_seit: '',
    Name_der_letzten_Krankenkasse: '',
    TTMMJJJJ: '',
    bis: '',
    pflichtversichert: false,
    privat: false,
    freiwillig_versichert: false,
    nicht_gesetzl_vers: false,
    familienvers: false,
    Zuzug_Ausland: false,
    Familienangehörige: false,
    Änderung_Versicherungsstatus: false,
    Versicherungsstatus_unverändert: false,
    Datum_und_Unterschrift: '',
    VertriebspartnerVermittlerNr: '',
    MitarbeiterNr: '',
    Referenz_GP_Nr: '',
    AdrQuelleSpez: '',
    Datenschutzerklärung: false,
    Willenserklärung: false,
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const pdfBlob = await fillPdf(formData); // Call the API service
      const url = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${formData.Name}-Viactiv.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className='famielien-form-container'>
    <form onSubmit={handleSubmit} className="pdf-form form">
      <h1>Viactiv Form</h1>

      {/* Text Fields */}
      <h2>Ich möchte zum Mitglied der VIACTIV Krankenkasse werden</h2>
      <input name="Datum" value={formData.Datum} onChange={handleChange} placeholder="Datum" type="text" />
      <div className='row-2fileds'>
      <input name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" type="text" />
      <input name="Vorname" value={formData.Vorname} onChange={handleChange} placeholder="Vorname" type="text" />
      </div>
      <div className='row-2fileds'>
      <input name="Geburtsname" value={formData.Geburtsname} onChange={handleChange} placeholder="Geburtsname" type="text" />
      <input name="Geburtsort" value={formData.Geburtsort} onChange={handleChange} placeholder="Geburtsort" type="text" />
      </div>
      <div className='row-2fileds'>
      <input maxLength="2" name="Geburtsland" value={formData.Geburtsland} onChange={handleChange} placeholder="Geburtsland" type="text" />
      <input name="Geburtsdatum" value={formData.Geburtsdatum} onChange={handleChange} placeholder="Geburtsdatum" type="text" />
      </div>
      <input name="Staatsangehörigkeit" value={formData.Staatsangehörigkeit} onChange={handleChange} placeholder="Staatsangehörigkeit" type="text" />
      
      {/* Gender Checkboxes */}
      <div className="checkbox-group">
        <CustomCheckbox 
          label="Weiblich" 
          checked={formData.weiblich} 
          onChange={handleChange} 
          name="weiblich" 
        />
        <CustomCheckbox 
          label="Männlich" 
          checked={formData.männlich} 
          onChange={handleChange} 
          name="männlich" 
        />
        <CustomCheckbox 
          label="Divers" 
          checked={formData.divers} 
          onChange={handleChange} 
          name="divers" 
        />
      </div>
      <div className='row-2fileds'>
      <input name="Straße" value={formData.Straße} onChange={handleChange} placeholder="Straße" type="text" />
      <input name="Hausnummer" value={formData.Hausnummer} onChange={handleChange} placeholder="Hausnummer" type="text" />
      </div>
      <div className='row-2fileds'>
      <input name="PLZ" value={formData.PLZ} onChange={handleChange} placeholder="PLZ" type="text" />
      <input name="Ort" value={formData.Ort} onChange={handleChange} placeholder="Ort" type="text" />
      </div>
      <div className='row-2fileds'>
      <input name="Telefon" value={formData.Telefon} onChange={handleChange} placeholder="Telefon" type="text" />
      <input name="EMail" value={formData.EMail} onChange={handleChange} placeholder="E-Mail" type="email" />
      </div>
      {/* Additional Input Fields */}

  
      <input name="Datum_und_Unterschrift" value={formData.Datum_und_Unterschrift} onChange={handleChange} placeholder="Datum und Unterschrift" type="text" />
     
      <div className='row-2fileds'>
      <input name="VertriebspartnerVermittlerNr" value={formData.VertriebspartnerVermittlerNr} onChange={handleChange} placeholder="Vertriebspartner Nr." type="text" />
      <input name="MitarbeiterNr" value={formData.MitarbeiterNr} onChange={handleChange} placeholder="Mitarbeiter Nr." type="text" />
      </div>
      <div className='row-2fileds'>
      <input name="Referenz_GP_Nr" value={formData.Referenz_GP_Nr} onChange={handleChange} placeholder="Referenz GP Nr." type="text" />
      <input maxLength="2" name="AdrQuelleSpez" value={formData.AdrQuelleSpez} onChange={handleChange} placeholder="AdrQuelle Spezial" type="text" />
      </div>
      {/* More Checkboxes */}
      <div className="checkbox-group">
      
        <h3>Familienstand:</h3>
        <div className='row-2check'>
        <CustomCheckbox 
          label="Ledig" 
          checked={formData.ledig} 
          onChange={handleChange} 
          name="ledig" 
        />
        <CustomCheckbox 
          label="Verheiratet" 
          checked={formData.verheiratet_Familienstand} 
          onChange={handleChange} 
          name="verheiratet_Familienstand" 
        />
        <CustomCheckbox 
          label="Lebenspartnerschaft" 
          checked={formData.Lebenspartnersch} 
          onChange={handleChange} 
          name="Lebenspartnersch" 
        />
        
        </div>
        <div class="decorative-line"></div>
        <div>
        <h3>Angaben zu meinem Versicherungsverhältnis:</h3>
        
      <input name="Name_des_Arbeitgebers" value={formData.Name_des_Arbeitgebers} onChange={handleChange} placeholder="Name des Arbeitgebers" type="text" />
      <div className='row-3fileds'>
      <input name="Straße_2" value={formData.Straße_2} onChange={handleChange} placeholder="Straße 2" type="text" />
    
      
      <input name="Hausnummer_2" value={formData.Hausnummer_2} onChange={handleChange} placeholder="Hausnummer 2" type="text" />
      <input name="PLZ_2" value={formData.PLZ_2} onChange={handleChange} placeholder="PLZ 2" type="text" />
     
      
      <input name="Ort_2" value={formData.Ort_2} onChange={handleChange} placeholder="Ort 2" type="text" />
      </div>
       <input name="Beschäftigt_seit" value={formData.Beschäftigt_seit} onChange={handleChange} placeholder="Beschäftigt seit" type="text" />
      </div>
      
        <div className='row-2check'>
        <CustomCheckbox 
          label="Beschäftigt" 
          checked={formData.beschäftigt} 
          onChange={handleChange} 
          name="beschäftigt" 
        />
        <CustomCheckbox 
          label="Ausbildung" 
          checked={formData.Ausbildung} 
          onChange={handleChange} 
          name="Ausbildung" 
        />
        <CustomCheckbox 
          label="Rente" 
          checked={formData.Rente} 
          onChange={handleChange} 
          name="Rente" 
        />
        <CustomCheckbox 
          label="Bin freiwillig versichert" 
          checked={formData['bin freiwillig versichert']} 
          onChange={handleChange} 
          name="bin freiwillig versichert" 
        />
        <CustomCheckbox 
          label="Ich studiere" 
          checked={formData['ich studiere']} 
          onChange={handleChange} 
          name="ich studiere" 
        />
        <CustomCheckbox 
          label="AL-Geld I" 
          checked={formData['AL-Geld I']} 
          onChange={handleChange} 
          name="AL-Geld I" 
        />
        <CustomCheckbox 
          label="AL-Geld II" 
          checked={formData['AL-Geld II']} 
          onChange={handleChange} 
          name="AL-Geld II" 
        />
        <CustomCheckbox 
          label="Minijob" 
          checked={formData.Minijob} 
          onChange={handleChange} 
          name="Minijob" 
        />
        <CustomCheckbox 
          label="Selbstständig" 
          checked={formData.selbstständig} 
          onChange={handleChange} 
          name="selbstständig" 
        />
        <CustomCheckbox 
          label="mein Einkommen liegt über der Jahresarbeitsentgeltgrenze
(jährlich 69.300 Euro – Stand 2024)" 
          checked={formData.Jaeg} 
          onChange={handleChange} 
          name="Jaeg" 
        />
        </div>
        <div>
        <h3>Ich war zuletzt versichert</h3>
        
      <input name="Name_der_letzten_Krankenkasse" value={formData.Name_der_letzten_Krankenkasse} onChange={handleChange} placeholder="Letzte Krankenkasse" type="text" />
      <div className='row-3fileds'>
        <input name="TTMMJJJJ" value={formData.TTMMJJJJ} onChange={handleChange} placeholder="Von TT/MM/JJJJ" type="text" />
      <input name="bis" value={formData.bis} onChange={handleChange} placeholder="Bis TT/MM/JJJJ" type="text" /></div>
        <div className='row-2check'>
        <CustomCheckbox 
          label="Pflichtversichert" 
          checked={formData.pflichtversichert} 
          onChange={handleChange} 
          name="pflichtversichert" 
        />
        <CustomCheckbox 
          label="Privat" 
          checked={formData.privat} 
          onChange={handleChange} 
          name="privat" 
        />
        <CustomCheckbox 
          label="Freiwillig versichert" 
          checked={formData.freiwillig_versichert} 
          onChange={handleChange} 
          name="freiwillig_versichert" 
        />
        <CustomCheckbox 
          label="Nicht gesetzlich versichert" 
          checked={formData.nicht_gesetzl_vers} 
          onChange={handleChange} 
          name="nicht_gesetzl_vers" 
        />
        <CustomCheckbox 
          label="Familienversichert" 
          checked={formData.familienvers} 
          onChange={handleChange} 
          name="familienvers" 
        />
        <CustomCheckbox 
          label="Zuzug aus dem Ausland" 
          checked={formData.Zuzug_Ausland} 
          onChange={handleChange} 
          name="Zuzug_Ausland" 
        />
        <CustomCheckbox 
          label="Es sollen Familienangehörige kostenfrei mitversichert werden. Bitte senden Sie mir den erforderlichen Fragebogen zu" 
          checked={formData.Familienangehörige} 
          onChange={handleChange} 
          name="Familienangehörige" 
        />
        </div>
        </div>
        <div class="decorative-line"></div>
        <div>
        <h3>Grund für den Kassenwechsel</h3>
        <div className='row-2check'>
        <CustomCheckbox 
          label="Mein Versicherungsstatus ändert sich.
(z. B. Aufnahme einer neuen Beschäftigung/Ausbildung/Studium etc.)" 
          checked={formData.Änderung_Versicherungsstatus} 
          onChange={handleChange} 
          name="Änderung_Versicherungsstatus" 
        />
        <CustomCheckbox 
          label="Mein Versicherungsstatus ist unverändert.
(Bitte informieren Sie meine Vorkasse über meinen Kündigungswunsch.)" 
          checked={formData.Versicherungsstatus_unverändert} 
          onChange={handleChange} 
          name="Versicherungsstatus_unverändert" 
        />
        </div>
        </div>
      </div>

      {/* Submit Button */}
      <button type="submit">Submit</button>
    </form>
    <button onClick={handleSendLink}>إرسال الرابط عبر WhatsApp</button>
      {link && <SignPdf link={link} />}</div>
  );
};

export default PdfForm;
