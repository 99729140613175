// src/components/ChangePassword.js
import React, { useState, useEffect } from 'react';
import { getAll, updateUserPassword } from '../../services/api';
import './ChangePass.css'

const ChangePass = () => {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Fetch all users for the superAdmin to select from
        const fetchUsers = async () => {
            try {
                const usersData = await getAll(); // Fetches all users from the server
                setUsers(usersData.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!selectedUserId || !newPassword) {
            setErrorMessage('Please select a user and provide a new password.');
            return;
        }

        try {
            await updateUserPassword(selectedUserId, newPassword);
            setSuccessMessage('Password updated successfully.');
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('Error updating password.');
            setSuccessMessage('');
        }
    };

    return (
        <div className='ch-pass-div'>
    <h2 className='change-pass-head'>Change User Password</h2>
   

    <form className='change-pass-form' onSubmit={handleChangePassword}>
        <div className="form-group">
            <label>Select User:</label>
            <select
                value={selectedUserId}
                onChange={(e) => setSelectedUserId(e.target.value)}
                required
            >
                <option value="">--Select User--</option>
                {users.map((user) => (
                    <option key={user.id} value={user.id}>
                        {user.username}
                    </option>
                ))}
            </select>
        </div>

        <div className="form-group">
            <label>New Password:</label>
            <input
            className='Pass-input'
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
            />
        </div>

        <button type="submit">Change Password</button>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
    </form>

</div>
    );
};

export default ChangePass;
