import React, { useRef, useState, useEffect } from 'react';
import './BigDirekt.css'; // Importing the new CSS file
import { fillBigPdf,getAllEmployees,getClientsByEmployeeId } from '../../services/api';
import CustomCheckbox from '../CustomCheckbox';
import SignaturePad from 'signature_pad';

const BigDirektForm = () => {
  const [haupt, setHaupt] = useState(false);

  const hadleHaupt=(e)=>{
    setHaupt(e.target.value)
  }

  const [clients, setClients] = useState([]);
  const [clientOptions, setClientOptions] = useState([]); // Suggested clients
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState('');
  const [employee_Id, setEmployeeId] = useState("");
  useEffect(() => {
    const fetchEmployees = async () => {
        try {
            const response = await getAllEmployees();
            
            setEmployees(response.data); // Set employees in state
        } catch (err) {
            console.error('Error fetching employees:', err);
            setError('Failed to fetch employees.'); // Set error state
        }
    };
    fetchEmployees(); // Call fetch function
}, []); // Run on mount only

const handleEmployeeSelect = async (e) => {
  const employeeId = e.target.value;
  setFormData((prevData) => ({ ...prevData, employeeId }));
setClients([]);
  if (employeeId) {
    setEmployeeId(employeeId);
      const response = await getClientsByEmployeeId(employeeId);
     setClients(response.data);
  }
};

const handleNameChange = (e) => {
  const { name, type, value, checked } = e.target;
  setFormData({
    ...formData,
    [name]: type === 'checkbox' ? checked : value,
  });

  // Filter clients by matching name (case-insensitive) with check for client.name
  if (value) {
      const filteredOptions = clients.filter((client) =>
          client.nachname && client.nachname.toLowerCase().includes(value.toLowerCase())
      );
      
      setClientOptions(filteredOptions);
  } else {
      setClientOptions([]);
  }
};



const handleClientSelect = (client) => {
  console.log('haupt is : ', haupt);
  
  if (haupt==='true') {
    setFormData((prevData) => ({
      ...prevData,
      hauptname:client.nachname,
      Vorname: client.vornname,
      Name: client.nachname,
      Mobil: client.number,
      clientId: client.id,
      geburtstage: client.geburtstage,
      employee_Id: client.employee_id,
    }));

    
  } else{
    setFormData((prevData) =>({
      ...prevData,
      hauptname:client.nachname,
      Vorname:'',
      Name:'',
      Mobil:'',
      geburtstage: '',
      clientId: client.id,
      employee_Id: client.employee_id,
    }))
  }
  setClientOptions([]); // Clear the options only if haupt is true
};

  const [formData, setFormData] = useState({
    hauptname:'',
    clientId: '',
    employee_Id:'',
    signature: '',
    BIGVersichertennummer_meines_Werbers: '',
    Name: '',
    Arbeitgeber: '',
    Vorname: '',
    Geburtsname: '',
    EMail: '',
    Geburtsort: '',
    Straße: '',
    Hausnummer: '',
    Geburtsland: '',
    PLZ: '',
    Ort: '',
    SteuerIdentifikationsnummer_seofern_bekannt: '',
    Versichertennummer: '',
    RentenSozialversicherungsnummer: '',
    Vorwahl: '',
    Festnetz: '',
    Vorwahl_2: '',
    Mobil: '',
    Ort_2: '',
    Straße_2: '',
    Hausnummer_2: '',
    PLZ_2: '',
    Ort_3: '',
    Straße_3: '',
    Hausnummer_3: '',
    PLZ_3: '',
    Kundennummer: '',
    Mitglied_ab_af_date: '',
    Geburtsdatum_af_date: '',
    Datum3_af_date: '',
    Familienstand_seit: '',
    Datum_der_Änderung_im_Versicherungsverhältnis_af_date: '',
    selbsständig_seit_af_date: '',
    Datum6_af_date: '',
    Vermittlernummer: '',
    KrankenkasseKrankenversicherung:'',
    männlich: false,
    weiblich: false,
    unbestimmt: false,
    divers: false,
    ledig: false,
    verheiratet: false,
    LPartG:false,
    verwitwet:false,
    getrennt_lebend: false,
    geschieden: false,
    ja_Bitte_fügen:false,
    nein:false,
    ja_2: false,
    nein_2: false,
    Familienangehörige: false,
    gesetzlich: false,
    privat: false,
    Arbeitnehmerin: false,
    Sozialgeldbezieherin: false,
    Leistungsbezieherin: false,
    MeineEinkommen: false,
    zusätzlich_selbstständig: false,
    selbst_versichert: false,
    familienversichert: false,
    privat_versichert: false,
    im_Ausland_versichert: false,
    Ich_habe_seitab_dem: false,
    selbstständig_tätig_seit: false,
    Beamtin_Beamter: false,
    Studierender_Schülerin: false,
    Rentnerin_Rentenantragstellerin: false,
    nicht_erwerbstätig: false,
    wöchentliche_Arbeitszeit: '',
    ja: false,
    nein_2: false,
    ohne_Anspruch_auf_Krankengeld: false,
    mit_Anspruch_auf_gesetzliches_Krankengeld: false,
    übersteigt_die_Beitragsbemessungsgrenze: false,
    Alle_Einkünfte: false,
    überweise_ich_selbst: false,
    werden_von_meinem_Arbeitgeber_überwiesen: false,
    sollen_bis_auf_Widerruf: false,
    an_mich: false,
    an_meinen_Vermittler: false,
    Ich_möchte_den_Newsletter: false,
    Hiermit_willige_ich_ein: false,

  });
  const signaturePadRef = useRef(null); // Ref for SignaturePad
  const canvasRef = useRef(null); // Ref for the canvas element

  // Initialize the signature pad once the canvas is ready
  useEffect(() => {
    if (canvasRef.current && !signaturePadRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    }
  }, [canvasRef]);

  // Clear the signature pad
  const handleClearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

   

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {

    // Ensure the signature pad is not empty
    if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) {
      alert('Please provide a signature.');
      return;
    }

    // Get the signature as a data URL
    const signatureDataURL = signaturePadRef.current.toDataURL();

    // Prepare form data with the signature
    const updatedFormData = {
      ...formData,
      signature: signatureDataURL,
    };
    e.preventDefault();
    try {
      const pdfBlob1=await fillBigPdf(updatedFormData);
      //alert('Form submitted successfully!');
      const url = window.URL.createObjectURL(new Blob([pdfBlob1], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${formData.Name}-21.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form.');
    }
  };
  

  return (
    <div className="new-form-container">
      <h1 className='head-Big'>Mitgliedsantrag- Big Direkt</h1>
      <form onSubmit={handleSubmit} className="new-form">
        <h2 className="form-header">New Form Submission</h2>

        {/* Personal Information */}
        <div className='person1'>
        <div className='margin-bot'>
          
        <div className='personal-info2'>
        <div className='personal-info1' >
                    <label htmlFor="employeeId">Mitarbeiter auswahlen:</label>
                    <select 
                        id="employeeId" 
                        name="employeeId" 
                        value={formData.employeeId} 
                        onChange={handleEmployeeSelect}
                        required
                    >
                        <option value="" disabled>Mitarbeiter auswahlen</option>
                        {employees.map((employee) => (
                            <option key={employee.id} value={employee.id} style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                                {employee.username.split("@")[0]} {/* Display employee name */}
                            </option>
                        ))}
                    </select>

                  
                  
            
            <label>is Haupt:</label>
                <label>
                    <input
                    className='radio'
                        type="radio"
                        name="haupt"
                        value={true}
                      
                        onChange={hadleHaupt}
                    />
                    - yes
                </label>
                <label>
                    <input
                    className='radio'
                        type="radio"
                        name="haupt"
                        value={false}
                        
                        onChange={hadleHaupt}
                    />
                   - No
                </label>
                </div></div>
                {clientOptions.length > 0 && (
                    <ul className="client-options">
                        {clientOptions.map((client) => (
                            <li key={client.id} onClick={() => handleClientSelect(client)}>
                                {client.nachname} - {client.geburtstage}
                            </li>
                        ))}
                    </ul>
                )}
          
          <div className="personal-info">
            <input
            name="hauptname"
            value={formData.hauptname}
            onInput={handleNameChange}
            placeholder="Haupt Kunde"
            type="text"
           

            className="form-input"
          />
          
           </div></div>
         
        <h2 className="form-section-header">Personal Information</h2>
        
        <div className="form-group">
        <div className='personal-info1'>
        <label className='label-Big'>
        Name
          <input
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
            type="text"
            className="form-input"
          
          /></label>
          <label className='label-Big'>
          Vorname
          <input
            name="Vorname"
            value={formData.Vorname}
            onChange={handleChange}
            placeholder="Vorname"
            type="text"
            className="form-input"
          
          /></label>
           <label className='label-Big'>
           Geburtsname
          <input
            name="Geburtsname"
            value={formData.Geburtsname}
            onChange={handleChange}
            placeholder="Geburtsname"
            type="text"
            className="form-input"
          
          /></label>
         
        
        </div>
        </div>
        {/* guburtstag Information */}
        <div className='personal-info1'>
        <label className='label-Big'>
        Geburtsort
        <input
            name="Geburtsort"
            value={formData.Geburtsort}
            onChange={handleChange}
            placeholder="Geburtsort"
            type="text"
            className="form-input"
          
          /></label>
          <label className='label-Big'>
          Geburtsdatum af date
        <input
            name="Geburtsdatum_af_date"
            value={formData.Geburtsdatum_af_date}
            onChange={handleChange}
            placeholder="Geburtsdatum af date"
            type="text"
            className="form-input"
            maxLength={10}
            minLength={10}
          
          /></label>
          <label className='label-Big'>
          Geburtsland
        <input
            name="Geburtsland"
            value={formData.Geburtsland}
            onChange={handleChange}
            placeholder="Geburtsland"
            type="text"
            className="form-input"
          
          />
          </label>
          </div>
          {/* Address Information */}
          
          <div className="form-group">
          <div className='personal-info1'>
          <label className='label-Big'>
          Straße
          <input
            name="Straße"
            value={formData.Straße}
            onChange={handleChange}
            placeholder="Straße"
            type="text"
            className="form-input"
          
          />
          </label>
          <label className='label-Big'>
          Hausnummer
          <input
            name="Hausnummer"
            value={formData.Hausnummer}
            onChange={handleChange}
            placeholder="Hausnummer"
            type="text"
            className="form-input"
          
          /></label>
             <label className='label-Big'>
             PLZ
          <input
            name="PLZ"
            value={formData.PLZ}
            onChange={handleChange}
            placeholder="PLZ"
            type="text"
            className="form-input"
          
          /></label>
           <label className='label-Big'>
           Ort
          <input
            name="Ort"
            value={formData.Ort}
            onChange={handleChange}
            placeholder="Ort"
            type="text"
            className="form-input"
          
          /></label>
        </div>
          </div>
          <div className='personal-info1'>
          <label className='label-Big'>
          BIGVersichertennummer
          <input
            name="BIGVersichertennummer_meines_Werbers"
            value={formData.BIGVersichertennummer_meines_Werbers}
            onChange={handleChange}
            placeholder="BIG Versichertennummer meines Werbers"
            type="text"
            className="form-input"
          /></label>
          <label className='label-Big'>
          Mobil
        <input
            name="Mobil"
            value={formData.Mobil}
            onChange={handleChange}
            placeholder="Mobil"
            type="text"
            className="form-input"
          /></label>
          <label className='label-Big'>
          EMail
          <input
            name="EMail"
            value={formData.EMail}
            onChange={handleChange}
            placeholder="Email"
            type="text"
            className="form-input"
          
          /></label>
          </div>
          <p className="form-header">Gender</p>
        <div className="checkbox-group">
            <CustomCheckbox
              name="männlich"
              checked={formData.männlich}
              onChange={handleChange}
              label="männlich"
            />

         
            <CustomCheckbox
            label="weiblich"
              name="weiblich"
              checked={formData.weiblich}
              onChange={handleChange}
              className="form-checkbox"
            />
            
        {/*
        
            <CustomCheckbox
              name="unbestimmt"
              checked={formData.unbestimmt}
              onChange={handleChange}
              className="form-checkbox"
              label='unbestimmt'
            />
         
          
            <CustomCheckbox
            label="divers"
              name="divers"
              checked={formData.divers}
              onChange={handleChange}
              className="form-checkbox"
            />
          */}
        </div>
        <p className="form-header">Family Status</p>
       
        <div className="checkbox-group">
  
  <CustomCheckbox
  label="ledig"
    name="ledig"
    checked={formData.ledig}
    onChange={handleChange}
    className="form-checkbox"
  />


  <CustomCheckbox
  label="verheiratet"
    name="verheiratet"
    checked={formData.verheiratet}
    onChange={handleChange}
    className="form-checkbox"
  />
  {/*
  <CustomCheckbox
  label="LPartG"
    name="LPartG"
    checked={formData.LPartG}
    onChange={handleChange}
    className="form-checkbox"
  />*/}
  <CustomCheckbox
  label="verwitwet"
    name="verwitwet"
    checked={formData.verwitwet}
    onChange={handleChange}
    className="form-checkbox"
  />


  <CustomCheckbox
  label="getrennt_lebend"
    name="getrennt_lebend"
    checked={formData.getrennt_lebend}
    onChange={handleChange}
    className="form-checkbox"
  />
  

  <CustomCheckbox
  label="geschieden"
    name="geschieden"
    checked={formData.geschieden}
    onChange={handleChange}
    className="form-checkbox"
  />
  {/* <input
  name="Datum3_af_date"
  value={formData.Datum3_af_date}
  onChange={handleChange}
  placeholder="seit"
  type="text"
  className="form-input"
  maxLength={10}
  minLength={10}

/>*/}

</div>
{/* 
<div className='personal-info'>
        <input
            name="RentenSozialversicherungsnummer"
            value={formData.RentenSozialversicherungsnummer}
            onChange={handleChange}
            placeholder="RentenSozialversicherungsnummer"
            type="text"
            className="form-input"
          />
        
            <input
            name="SteuerIdentifikationsnummer_seofern_bekannt"
            value={formData.SteuerIdentifikationsnummer_seofern_bekannt}
            onChange={handleChange}
            placeholder="SteuerIdentifikationsnummer seofern bekannt"
            type="text"
            className="form-input"
          />
        <input
            name="Versichertennummer"
            value={formData.Versichertennummer}
            onChange={handleChange}
            placeholder="Versichertennummer"
            type="text"
            className="form-input"
          />
       </div>
       */}
      
           {/* contact Information 
           

        {/*   <input
            name="Vorwahl_2"
            value={formData.Vorwahl_2}
            onChange={handleChange}
            placeholder="Vorwahl 2"
            type="text"
            className="vowahl "
          />
          */}
       
       <p className="form-header">ich habe kinder</p>
        <div className="checkbox-group">
        <CustomCheckbox
            label="ja"
              name="ja_Bitte_fügen"
              checked={formData.ja_Bitte_fügen}
              onChange={handleChange}
              className="form-checkbox"
            />
       
        <CustomCheckbox
            label="nein"
              name="nein"
              checked={formData.nein}
              onChange={handleChange}
              className="form-checkbox"
            />
        </div>

        <div className="checkbox-group">
        <CustomCheckbox
            label="Familienangehörige sollen mitversichert werden."
              name="Familienangehörige"
              checked={formData.Familienangehörige}
              onChange={handleChange}
              className="form-checkbox"
            />
            </div>
        <h3 className="form-header" >Mein Lebenspartner ist</h3>
            <div className="checkbox-group">
            <CustomCheckbox
            label="gesetzlich"
              name="gesetzlich"
              checked={formData.gesetzlich}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="privat"
              name="privat"
              checked={formData.privat}
              onChange={handleChange}
              className="form-checkbox"
            />
             <input
            name="Familienstand_seit"
            value={formData.Familienstand_seit}
            onChange={handleChange}
            placeholder="kranken_bei"
            type="text"
            className="form-input"
          
          />
            
     
            

            </div>
            
            <h2>Ich war bisher versichert</h2>
            <input
            name="KrankenkasseKrankenversicherung"
            value={formData.KrankenkasseKrankenversicherung}
            onChange={handleChange}
            placeholder="Krankenversicherung bei"
            type="text"
            className="form-input"
          />
        
           {/*Mein/-e Ehe-/Lebenspartner/-in gem. LPartG ist*/}
           
          
           
          <div className="checkbox-group">
            <CustomCheckbox
            label="selbst versichert"
              name="selbst_versichert"
              checked={formData.selbst_versichert}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="familienversichert"
              name="familienversichert"
              checked={formData.familienversichert}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="privat_versichert"
              name="privat_versichert"
              checked={formData.privat_versichert}
              onChange={handleChange}
              className="form-checkbox"
            />
         {/* <div className='personal-info'>
          <input
            name="Straße_2"
            value={formData.Straße_2}
            onChange={handleChange}
            placeholder="Straße"
            type="text"
            className="form-input"
          
          />
          <input
            name="Hausnummer_2"
            value={formData.Hausnummer_2}
            onChange={handleChange}
            placeholder="Hausnummer"
            type="text"
            className="form-input"
          
          />
       
          <input
            name="PLZ_2"
            value={formData.PLZ_2}
            onChange={handleChange}
            placeholder="PLZ"
            type="text"
            className="form-input"
          
          />
          <input
            name="Ort_2"
            value={formData.Ort_2}
            onChange={handleChange}
            placeholder="Ort"
            type="text"
            className="form-input"
          
          />
        </div>
        <CustomCheckbox
            label="Ich_habe_seitab_dem"
              name="Ich_habe_seitab_dem"
              checked={formData.Ich_habe_seitab_dem}
              onChange={handleChange}
              className="form-checkbox"
            />
              <input
            name="Datum_der_Änderung_im_Versicherungsverhältnis_af_date"
            value={formData.Datum_der_Änderung_im_Versicherungsverhältnis_af_date}
            onChange={handleChange}
            placeholder="Datum_der_Änderung_im_Versicherungsverhältnis_af_date"
            type="text"
            className="form-input"
            minLength={10}
            maxLength={10}
          />*/}
            </div>
            <h2>Arbeit details</h2>

<div className="checkbox-group">

<CustomCheckbox
            label="Arbeitnehmer/in"
              name="Arbeitnehmerin"
              checked={formData.Arbeitnehmerin}
              onChange={handleChange}
              className="form-checkbox"
            />
<CustomCheckbox
            label="Mein Einkommen liegt über der Jahresarbeitsentgeltgrenze"
              name="MeineEinkommen"
              checked={formData.MeineEinkommen}
              onChange={handleChange}
              className="form-checkbox"
            />
            <div className='personal-info'>
<input
            name="Arbeitgeber"
            value={formData.Arbeitgeber}
            onChange={handleChange}
            placeholder="Arbeitgeber"
            type="text"
            className="form-input"
          
          />
           <div className='personal-info'>
          <input
            name="Straße_3"
            value={formData.Straße_3}
            onChange={handleChange}
            placeholder="Straße"
            type="text"
            className="form-input"
          
          />
          <input
            name="Hausnummer_3"
            value={formData.Hausnummer_3}
            onChange={handleChange}
            placeholder="Hausnummer"
            type="text"
            className="form-input"
          
          />
       
          <input
            name="PLZ_3"
            value={formData.PLZ_3}
            onChange={handleChange}
            placeholder="PLZ"
            type="text"
            className="form-input"
          
          />
          <input
            name="Ort_3"
            value={formData.Ort_3}
            onChange={handleChange}
            placeholder="Ort"
            type="text"
            className="form-input"
          
          />
        </div>
        
</div>
<div class="decorative-line"></div>
{/*<CustomCheckbox
            label="zusätzlich_selbstständig"
              name="zusätzlich_selbstständig"
              checked={formData.zusätzlich_selbstständig}
              onChange={handleChange}
              className="form-checkbox"
            />
<CustomCheckbox
            label="Sozial geldbezieherin"
              name="Sozialgeldbezieherin"
              checked={formData.Sozialgeldbezieherin}
              onChange={handleChange}
              className="form-checkbox"
            />*/}
<CustomCheckbox
            label="Leistungsbezieherin bei joncenter / agentur"
              name="Leistungsbezieherin"
              checked={formData.Leistungsbezieherin}
              onChange={handleChange}
              className="form-checkbox"
            />
                 <input
            name="Kundennummer"
            value={formData.Kundennummer}
            onChange={handleChange}
            placeholder="Kundennummer"
            type="text"
            className="form-input"
          
          />
           </div>
           <div className="checkbox-group">
           
           <CustomCheckbox
            label="selbstständig_tätig_seit"
              name="selbstständig_tätig_seit"
              checked={formData.selbstständig_tätig_seit}
              onChange={handleChange}
              className="form-checkbox"
            />
             <input
            name="selbsständig_seit_af_date"
            value={formData.selbsständig_seit_af_date}
            onChange={handleChange}
            placeholder="selbsständig seit af date"
            type="text"
            className="form-input"
            minLength={10}
            maxLength={10}
          />
          {/*
           <input
            name="wöchentliche_Arbeitszeit"
            value={formData.wöchentliche_Arbeitszeit}
            onChange={handleChange}
            placeholder="wöchentliche Arbeitszeit"
            type="text"
            className="form-input"
            minLength={2}
            maxLength={2}
          />
              */}
          </div>
          <div className='checkbox-group'>
            <CustomCheckbox
            label="Studierender_Schülerin"
              name="Studierender_Schülerin"
              checked={formData.Studierender_Schülerin}
              onChange={handleChange}
              className="form-checkbox"
            />
{/*<CustomCheckbox
            label="nicht erwerbstätig"
              name="nicht_erwerbstätig"
              checked={formData.nicht_erwerbstätig}
              onChange={handleChange}
              className="form-checkbox"
            />*/}
</div>
<input
            name="Datum6_af_date"
            value={formData.Datum6_af_date}
            onChange={handleChange}
            placeholder="Datum"
            type="text"
            className="form-input"
            minLength={10}
            maxLength={10}
          />
        </div>

        
          
          {/* Versicherung+steuer nummer Information */}

        {/* Additional Address Information */}
        

        {/* Gender Checkboxes */}
        
       
        
        

  
        {/* kinder details */}
        
        
{/* Arbeit status */}

           {/*
            <div class="decorative-line"></div>{/*
            <h4 > Die Krankenversicherung soll durchgeführt werden</h4>
            <div className='checkbox-group'>
            <CustomCheckbox
            label="ohne Anspruch auf Krankengeld"
              name="ohne_Anspruch_auf_Krankengeld"
              checked={formData.ohne_Anspruch_auf_Krankengeld}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="mit Anspruch auf gesetzliches Krankengeld"
              name="mit_Anspruch_auf_gesetzliches_Krankengeld"
              checked={formData.mit_Anspruch_auf_gesetzliches_Krankengeld}
              onChange={handleChange}
              className="form-checkbox"
            />
            </div>*
            <div class="decorative-line"></div>
            <h2>Die Betrage</h2>
            <div className='checkbox-group'>
            <CustomCheckbox
            label="überweise ich selbst"
              name="überweise_ich_selbst"
              checked={formData.überweise_ich_selbst}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="werden von meinem Arbeitgeber überwiesen"
              name="werden_von_meinem_Arbeitgeber_überwiesen"
              checked={formData.werden_von_meinem_Arbeitgeber_überwiesen}
              onChange={handleChange}
              className="form-checkbox"
            />
            <CustomCheckbox
            label="sollen bis auf Widerruf per SEPA-Basislastschrift-Mandat abgebucht werden"
              name="sollen_bis_auf_Widerruf"
              checked={formData.sollen_bis_auf_Widerruf}
              onChange={handleChange}
              className="form-checkbox"
            />
            </div>/}
             {/* Signature Field */}
                <div>
                
                   
              
        <h3>Signature</h3>
        <canvas ref={canvasRef} className="signature-pad" width="1000" height="200"></canvas>
        <button type="button" onClick={handleClearSignature} className="clear-button">
          Clear Signature
        </button>
        </div>
        {/* Submit Button */}
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default BigDirektForm;
<h2>Arbeit details</h2>
