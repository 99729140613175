// ChildInfo.js
import React from 'react';

const ChildInfo3 = ({ title, formData, handleChange, namePrefix }) => {
  return (
    <div className='person'>
      <h3 className="form-section-header">{title}</h3>
      
      <div className='namen'>
        {/* Versichertennummer */}
        <label className='label-Big'>
          Versichertennummer
          <input
            name={`${namePrefix}_Versichertennummer`}
            value={formData[`${namePrefix}_Versichertennummer`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>
        
        {/* Rentenversicherungsnummer */}
        <label className='label-Big'>
          Rentenversicherungsnummer
          <input
            name={`${namePrefix}_Rentenversicherungsnummer`}
            value={formData[`${namePrefix}_Rentenversicherungsnummer`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>
      </div>

      <div className='namen'>
        {/* Geburtsname */}
        <label className='label-Big'>
          Geburtsname
          <input
            name={`${namePrefix}_Geburtsname`}
            value={formData[`${namePrefix}_Geburtsname`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>

        {/* Geburtsort */}
        <label className='label-Big'>
          Geburtsort
          <input
            name={`${namePrefix}_ort`}
            value={formData[`${namePrefix}_ort`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>
      </div>

      <div className='namen'>
        {/* Geburtsland */}
        <label className='label-Big'>
          Geburtsland
          <input
            name={`${namePrefix}_geburtsland`}
            value={formData[`${namePrefix}_geburtsland`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>

        {/* Staatsangehörigkeit */}
        <label className='label-Big'>
          Staatsangehörigkeit
          <input
            name={`${namePrefix}_staatsangehorigkeit`}
            value={formData[`${namePrefix}_staatsangehorigkeit`]}
            onChange={handleChange}
            type="text"
            className="form-input"
          />
        </label>
      </div>
    </div>
  );
};

export default ChildInfo3;
