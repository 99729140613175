import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { CircularProgress } from '@mui/material'; // Import a loading spinner (using MUI here)

// Configure the PDF URL
const SignDocument = () => {
  const { uniqueId } = useParams(); // Get uniqueId from the URL
  const [pdfUrl, setPdfUrl] = useState(''); // URL of the PDF to be displayed
  const [loading, setLoading] = useState(true); // Loading state for the spinner
  const signaturePadRef = useRef(null); // Ref to SignatureCanvas

  useEffect(() => {
    // Set the PDF URL based on uniqueId
    if (uniqueId) {
      setPdfUrl(`https://xn--kunde-sham-direkt-antrge-ccc.de:3000/antrags/_Bigs_fEMIELIEN_1730670603890.pdf`);
    }
  }, [uniqueId]);

  const handleIframeLoad = () => {
    setLoading(false); // Hide the loading spinner when iframe loads
  };

  const handleSubmit = async () => {
    const signatureData = signaturePadRef.current.toDataURL(); // Capture the signature in base64

    try {
      // Send the signature data to the backend to sign the PDF
      const response = await axios.post(`${process.env.REACT_APP_API1_URL}/twillo/sign-pdf/${uniqueId}`, {
        signatureData,
      });
      alert('Document signed successfully!');
    } catch (error) {
      alert('Error signing the document');
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Sign Document</h2>

      {/* Loading spinner to show until iframe is loaded */}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      )}

      {/* Display the PDF using an iframe, with responsive adjustments */}
      {pdfUrl && (
        <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%', height:'40vh' /* 16:9 aspect ratio */ }}>
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            onLoad={handleIframeLoad}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              display: loading ? 'none' : 'block', // Hide iframe while loading
            }}
            title="Document Viewer"
          />
        </div>
      )}

      <h3 style={{ marginTop: '1rem' }}>Sign Below</h3>

      {/* Signature pad to capture the user's signature */}
      <SignatureCanvas
  ref={signaturePadRef}
  penColor="black"
  canvasProps={{
    width: '250px', // Full width of the parent container
    height: 150,
    className: 'signature-canvas',
    maxWidth: '100%' // Ensure it doesn't exceed the width of the parent container
  }}
  style={{
    border: '1px solid #ddd',
    borderRadius: '4px',
    margin: '1rem 0',
    width: '100%', // Make sure it takes full width on mobile
    maxWidth: '100%', // Ensure it doesn't exceed parent container's width
    boxSizing: 'border-box' // Prevents the width from being affected by padding or border
  }}
/>
      <button onClick={handleSubmit} style={{ padding: '10px 20px', fontSize: '16px' }}>Submit Signature</button>
    </div>
  );
};

export default SignDocument;
