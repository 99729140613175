import React, { useState } from 'react';
import { login } from '../services/api';
import './login.css';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        try {
            const { token } = await login(username, password);
            if (token) {
                onLogin();
                navigate('/');
            } else {
                setError('Token not found in response.');
            }
        } catch (err) {
            setError(err.response?.data?.message || err.message);
        }
    };

    return (
        <div className="login-container">
            <h2 className='log-h'>Login</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Login;
