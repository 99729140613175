// src/components/Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link,useLocation  } from 'react-router-dom';
import './sidebar.css'; // Import the updated CSS file
import { useUser } from '../context/UserContext'; // Import user context
import { emailAouth } from '../services/api';

const Sidebar = ({ onLogout }) => { // Add onLogout as a prop
    const { user, setUser } = useUser(); // Access user context
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [selectedFile, setSelectedFile] = useState(null); // State to manage the selected file
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages
    const [successMessage, setSuccessMessage] = useState(null); // State for success messages



    const handleAuth = () => {
        emailAouth(); // Redirect to initiate Google OAuth
      };
    
    // Fetch user data when component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://xn--kunde-sham-direkt-antrge-ccc.de:3000/api/users/me', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const userData = await response.json();
                setUser(userData); // Update user context with the fetched data
            } catch (error) {
                console.error('Error fetching user data:', error);
                setErrorMessage('Failed to load user data. Please try again.');
                setUser(null); // Clear user data on error
            }
        };

        fetchUserData(); // Call the function to fetch user data
    }, [setUser]); // Dependencies array includes setUser

    const role = localStorage.getItem('role');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch('https://xn--kunde-sham-direkt-antrge-ccc.de:3000/api/updatepic/profile-picture', {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            const data = await response.json();
            console.log('Profile picture updated:', data);

            // Optionally fetch updated user data to reflect the change
            const userResponse = await fetch('https://xn--kunde-sham-direkt-antrge-ccc.de:3000/api/users/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!userResponse.ok) {
                throw new Error('Failed to fetch updated user data');
            }

            const updatedUserData = await userResponse.json();
            setUser(updatedUserData); // Update user context with the new data
            

            setSuccessMessage('Profile picture updated successfully!');
            setErrorMessage(null); // Clear previous errors
            setIsModalOpen(false); // Close the modal
        } catch (error) {
            console.error('Error uploading file:', error);
            setErrorMessage('Error uploading file. Please try again.');
            setSuccessMessage(null); // Clear previous success messages
        }
    };
    const location = useLocation();

  const isActive = (path) => location.pathname === path;


    return (
        <div className="sidebar-container">
            <div className="user-profile">
                <img
                    src={user?.profilePic ? `https://xn--kunde-sham-direkt-antrge-ccc.de:3000/${user.profilePic.replace(/\\/g, '/')}` : 'default-profile-pic-url.jpg'} // Use optional chaining and a default image
                    alt="User" 
                    className="profile-pic" 
                />
                <div className="username">{user?.username.split("@")[0] || "Username"}</div>
                <button onClick={() => setIsModalOpen(true)} className="upload-icon">
                    📷 {/* Replace with an actual icon or image */}
                </button>
               
            </div>
            <input type="text" className="search-input" placeholder="Search..." />
            <nav className="navigation">
                <ul>
                   {/*} <li><Link to="/dashboard">Dashboard</Link></li>
                    {role === 'admin' && (<li className={isActive('/antrags') ? 'active' : ''}><Link  to="/antrag">Antrag</Link></li>)}*/}
                    {role === 'admin' && (<li className={isActive('/register') ? 'active' : ''}><Link  to="/register">Neu Employee</Link></li>)}
                    {role === 'admin' && (<li className={isActive('/All-Kunden') ? 'active' : ''}><Link  to="/All-Kunden">Alle Antrage</Link></li>)}
                    {role === 'superAdmin' && (<li className={isActive('/All-Kunden') ? 'active' : ''}><Link  to="/All-Kunden">Alle Antrage</Link></li>)}
                    {role === 'service' && (<li className={isActive('/All-Kunden') ? 'active' : ''}><Link  to="/All-Kunden">Alle Antrage</Link></li>)}
                    {role === 'superAdmin' && (<li className={isActive('/change-password') ? 'active' : ''}><Link  to="/change-password">Change user passwords</Link></li>)}
                    {/*<li className={isActive('/myAntrags') ? 'active' : ''}><Link  to="/myAntrags" >My Antrags</Link></li>*/}
                    {role === 'employee' && (<li className={isActive('/Meine-Kunden') ? 'active' : ''}><Link  to="/Meine-Kunden" >Meine Kunden</Link></li>)}
                   {role === 'admin' && (< li className={isActive('/NeuKunde') ? 'active' : ''}><Link  to="/NeuKunde" >Neu Kunde</Link></li>)}
                   {role === 'admin' && (<li className={isActive('/PdfForm') ? 'active' : ''}><Link  to="/PdfForm">VIACTIV Form</Link></li>)}
                     {role === 'admin' && ( <li className={isActive('/BigPdfForm') ? 'active' : ''}><Link  to="/BigPdfForm">Big Direkt Form</Link></li>)}
                     {role === 'admin' && ( <li className={isActive('/BigBonus') ? 'active' : ''} ><Link   to="/BigBonus">BigBonus</Link></li>)}
                     {role === 'admin' && ( <li className={isActive('/Big-famielien') ? 'active' : ''} ><Link   to="/Big-famielien">Big Familien</Link></li>)}
                    {/*<button onClick={() => window.location.href = `http://localhost:3000/api/users/auth/${localStorage.getItem('user_id')}`}>
  Authenticate with Gmail
</button>*/}
                    {/* Add more links as necessary */}
                </ul>
            </nav>
            <button onClick={onLogout} className="logout-button">Logout</button> {/* Logout button */}

            {/* Modal for uploading a profile picture */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <h2>Update Photo</h2>
                        <input type="file" accept="image/*" onChange={handleFileChange}/>
                        <button onClick={handleUpload}>Upload</button>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sidebar;
