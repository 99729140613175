// ChildInfo.js
import React from 'react';

const ChildInfo = ({ title, formData, handleChange, namePrefix }) => {
  return (
    <div className='person'>
      <h3 className="form-section-header">{title}</h3>
      
      <div className='namen'>
        <label className='label-Big'>
          Die bisherige Versicherung endete am: (TT/MM/JJ)
          <input
            name={`${namePrefix}_oldVerEnd`}
            value={formData[`${namePrefix}_oldVerEnd`]}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
        </label>
        
        <label className='label-Big'>
          Name der Krankenkasse
          <input
            name={`${namePrefix}_oldVerName`}
            value={formData[`${namePrefix}_oldVerName`]}
            onChange={handleChange}
            placeholder=""
            type="text"
            className="form-input"
          />
        </label>
      </div>
      
      <div className='personal-info'>
        <div className="select-dropdown">
          <select
            name={`BIsherige_Vers_${namePrefix}`}
            value={formData[`BIsherige_Vers_${namePrefix}`]}
            onChange={handleChange}
          >
            <option value="">Art der bisherigen Versicherung</option>
            <option value="Auswahl1">selbst</option>
            <option value="Auswahl2">familienversichert</option>
            <option value="2">nicht</option>
          </select>
        </div>
      </div>
      
      <label className='label-Big'>
        Sofern zuletzt eine Familienversicherung bestand, Name und Vorname der Person,
        aus deren Mitgliedschaft die Familienversicherung abgeleitet wurde
        <input
          name={`${namePrefix}_otherVerName`}
          value={formData[`${namePrefix}_otherVerName`]}
          onChange={handleChange}
          placeholder=""
          type="text"
          className="form-input"
        />
      </label>
      
      <label className='label-Big'>
        Die bisherige Versicherung besteht weiter bei
        <input
          name={`${namePrefix}_NeuVerName`}
          value={formData[`${namePrefix}_NeuVerName`]}
          onChange={handleChange}
          placeholder="Big ?"
          type="text"
          className="form-input"
        />
      </label>
    </div>
  );
};

export default ChildInfo;
