import React, { useEffect, useState } from 'react';
import { getAllAntrags, createAntrag, updateAntrag, deleteAntrag, getAllEmployees } from '../services/api';
import './Antrag.css';

// Modal component for confirmation
const ConfirmationModal = ({ show, formData, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Review Your Antrag Submission</h2>
                <div className="modal-body">
                    <p><strong>ِVersicherung Numer :</strong> {formData.verNumer}</p>
                    <p><strong>Name:</strong> {formData.name}</p>
                    <p><strong>Nachname:</strong> {formData.nachName}</p>
                    <p><strong>Geburtstag:</strong> {formData.geburtstag}</p>
                    <p><strong>Antrag Start:</strong> {formData.antragStart}</p>
                    <p><strong>Sent Date:</strong> {formData.sent}</p>
                    <p><strong>End of Antrag:</strong> {formData.endOfAntrag}</p>
                    <p><strong>Type of Antrag:</strong> {formData.typeOfAntrag}</p>
                    <p><strong>Employee:</strong> {formData.employeeId}</p>
                </div>
                <div className="modal-footer">
                    <button className="confirm-btn" onClick={onConfirm}>Confirm</button>
                    <button className="cancel-btn" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};
const role = localStorage.getItem('role'); // Get the user role from localStorage
const Antrag = () => {
    const [antrags, setAntrags] = useState([]);
    const [formData, setFormData] = useState({
        verNumer:'',
        name: '',
        nachName: '',
        geburtstag: '',
        antragStart: '',
        sent: '',
        endOfAntrag: '',
        typeOfAntrag: '',
        employeeId: ''
    });
    const [editingAntragId, setEditingAntragId] = useState(null);
    const [error, setError] = useState('');
    
    const [showModal, setShowModal] = useState(false); // Modal state
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const [employees, setEmployees] = useState([]);
    // Fetch all employees
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await getAllEmployees();
                console.log('Fetched employees:', response.data); // Log fetched data
                setEmployees(response.data); // Set employees in state
            } catch (err) {
                console.error('Error fetching employees:', err);
                setError('Failed to fetch employees.'); // Set error state
            }
        };
        fetchEmployees(); // Call fetch function
    }, []); // Run on mount only

    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value })); // Update form data
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModal(true); // Show confirmation modal
    };

    // Handle confirmation in modal
    const handleConfirm = async () => {
        try {
            if (editingAntragId) {
                await updateAntrag(editingAntragId, formData); // Update existing Antrag
                setAntrags((prev) => 
                    prev.map((antrag) => (antrag.id === editingAntragId ? { ...antrag, ...formData } : antrag))
                );
            } else {
                const newAntrag = await createAntrag(formData); // Create new Antrag
                setAntrags((prev) => [...prev, newAntrag]); // Add new Antrag to state
            }
            setSuccessMessage('Your Antrag has been successfully submitted!'); // Set success message
            resetForm(); // Reset form
            
            // Clear the success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
        } catch (err) {
            console.error('Error saving antrag:', err);
            setError('Failed to save antrag.'); // Set error state
        }
        setShowModal(false); // Close modal
    };

    // Set form for editing
    const handleEdit = (antrag) => {
        setFormData(antrag); // Populate form with selected Antrag data
        setEditingAntragId(antrag.id); // Set editing state
    };

    // Handle delete
    const handleDelete = async (id) => {
        try {
            await deleteAntrag(id); // Delete Antrag
            setAntrags((prev) => prev.filter((antrag) => antrag.id !== id)); // Remove from state
        } catch (err) {
            console.error('Error deleting antrag:', err);
            setError('Failed to delete antrag.'); // Set error state
        }
    };

    // Reset form
    const resetForm = () => {
        setFormData({
            verNumer:'',
            name: '',
            nachName: '',
            geburtstag: '',
            antragStart: '',
            sent: '',
            endOfAntrag: '',
            typeOfAntrag: '',
            employeeId: ''
        });
        setEditingAntragId(null); // Clear editing state
    };

    return (
        <div className='container'>
            <h2>Aufträge Management</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>} {/* Success message display */}

            {/* Modal for confirmation */}
            <ConfirmationModal 
                show={showModal} 
                formData={formData} 
                onClose={() => setShowModal(false)} 
                onConfirm={handleConfirm} 
            />
            
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="employeeId">Mitarbeiter auswahlen:</label>
                    <select 
                        id="employeeId" 
                        name="employeeId" 
                        value={formData.employeeId} 
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled>Mitarbeiter auswahlen</option>
                        {employees.map((employee) => (
                            <option key={employee.id} value={employee.id} style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                                {employee.username.split("@")[0]} {/* Display employee name */}
                            </option>
                        ))}
                    </select>
                    <small>Select the employee associated with this Antrag.</small>
                </div>

                {/* Existing form fields for Antrag submission */}
                <div>
                    <label htmlFor="verNumer">Versicherung Nummer: </label>
                    <input 
                        type="text" 
                        id="verNumer" 
                        name="verNumer" 
                        placeholder="Enter Versicherung Nummer" 
                        value={formData.verNumer} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Enter the versicherung Nummer of the applicant.</small>
                </div>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Enter your name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Enter the first name of the applicant.</small>
                </div>
                
                <div>
                    <label htmlFor="nachName">Nachname:</label>
                    <input 
                        type="text" 
                        id="nachName" 
                        name="nachName" 
                        placeholder="Enter your last name" 
                        value={formData.nachName} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Enter the last name of the applicant.</small>
                </div>
                
                <div>
                    <label htmlFor="geburtstag">Geburtstag:</label>
                    <input 
                        type="date" 
                        id="geburtstag" 
                        name="geburtstag" 
                        value={formData.geburtstag} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Select the date of birth of the applicant.</small>
                </div>
                
                <div>
                    <label htmlFor="antragStart">Antrag Start:</label>
                    <input 
                        type="date" 
                        id="antragStart" 
                        name="antragStart" 
                        value={formData.antragStart} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Select the start date of the Antrag.</small>
                </div>
                
                <div>
                    <label htmlFor="sent">Einreichungsdatum:</label>
                    <input 
                        type="date" 
                        id="sent" 
                        name="sent" 
                        value={formData.sent} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Select the date the Antrag was sent.</small>
                </div>
                
                <div>
                    <label htmlFor="endOfAntrag">Vertragsende:</label>
                    <input 
                        type="date" 
                        id="endOfAntrag" 
                        name="endOfAntrag" 
                        value={formData.endOfAntrag} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Select the end date of the Vertrags.</small>
                </div>
                
                <div>
                    <label htmlFor="typeOfAntrag">Firma:</label>
                    <input 
                        type="text" 
                        id="typeOfAntrag" 
                        name="typeOfAntrag" 
                        placeholder="Enter type of Antrag" 
                        value={formData.typeOfAntrag} 
                        onChange={handleChange} 
                        required 
                    />
                    <small>Specify the Firma of Antrag (big direkt , sick leave).</small>
                </div>

                <button type="submit">{editingAntragId ? 'Update Antrag' : 'Create Antrag'}</button>
                <button type="button" onClick={resetForm}>Cancel</button>
            </form>

        </div>
    );
};

export default Antrag;
