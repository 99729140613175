// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/register';
import Login from './components/login';
import Dashboard from './components/dashboard';
import TicketDetail from './components/TicketDetail';
import Sidebar from './components/Sidebar'; // Import Sidebar
import Antrag from './components/Antrag'; // Import the Antrag component
import { UserProvider } from './context/UserContext'; // Import UserProvider
import MyAntrags from './components/MyAntrags';
import AllAntrags from './components/Allantrags';
import PdfForm from './components/PfdForm';
import ChangePass from './components/Changepasswords/ChangePass';
import BigDirektForm from './components/BigDirektForm/BigDirektForm';
import BigBonus from './components/BigBonus/BigBonus';
import BigFamielien from './components/BigFamilien/BigFamielien';
import AddClient from './components/NeuKunde/NeuKunde';
import ClientDetails from './components/client-details/Client';
import ClientsList from './components/client-details/ClientList';
import AllClients from './components/client-details/AllClients';
import SignDocument from './components/PdfSmsSign';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const role = localStorage.getItem('role');
    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
        window.location.href = '/login';
    };

    // Protected Route component
    const ProtectedRoute = ({ children, allowedRoles }) => {
        if (!isLoggedIn) {
            return <Navigate to="/login" />;
        }

        if (allowedRoles && !allowedRoles.includes(role)) {
            return <Navigate to="/" />;
        }

        return children;
    };


    return (
        <UserProvider> {/* Wrap the entire application in UserProvider */}
            <Router>
                <div style={{ display: 'flex' }}>
                    {isLoggedIn && <Sidebar onLogout={handleLogout} />} {/* Render Sidebar if logged in */}
                    <div style={{ marginLeft: isLoggedIn ? '250px' : '0', transition: 'margin 0.3s' }}> {/* Main content area */}
                        <Routes>
                            {/*<Route path="/tickets/:ticketId" element={<TicketDetail />} /> 
                            <Route path="/register" element={<Register />} />*/}
                            {!isLoggedIn && <Route path="/login" element={<Login onLogin={handleLogin} />} />}
                            <Route
                                path="/"
                                element={
                                    isLoggedIn ? (
                                        role === "admin" || role ==="superAdmin" || role ==="service" ? (<AllClients/>):(<ClientsList/>)
                                        
                                    ) : (
                                        <div style={{ textAlign: 'center', padding: '50px' }}>
                                            <h1>Welcome to the Cis System</h1>
                                            <p>Log in to your account , click below:</p>
                                            <div>
                                                <a href="/register" style={{ color: '#007bff', textDecoration: 'none', marginRight: '20px' }}>
                                                    Register Now
                                                </a>
                                                <a href="/login" style={{ color: '#007bff', textDecoration: 'none' }}>
                                                    Login
                                                </a>
                                            </div>
                                        </div>
                                    )
                                }
                            />
                            {/*<Route
                                path="/dashboard"
                                element={
                                    <ProtectedRoute>
                                        <Dashboard />
                                    </ProtectedRoute>
                                }
                            />*/}
                            {role === "admin" && (<Route
                                path="/antrag"
                                element={
                                    <ProtectedRoute>
                                        <Antrag />
                                    </ProtectedRoute>
                                }
                            />)}
                            {role === "admin" && (<Route
                                path="/allAntrags"
                                element={
                                    <ProtectedRoute>
                                        <AllAntrags />
                                    </ProtectedRoute>
                                }
                            />)}
                            {/*<Route
                                path="/myAntrags"
                                element={
                                    <ProtectedRoute>
                                        <MyAntrags />
                                    </ProtectedRoute>
                                }
                            />*/}
                            <Route
                                path="/PdfForm"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <PdfForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/BigPdfForm"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <BigDirektForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/register"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                     <Register />
                                </ProtectedRoute>
                                       
                                   
                                }
                            />

                           <Route
                                path="/change-password"
                                element={
                                    <ProtectedRoute allowedRoles={['superAdmin']}>
                                        <ChangePass />
                                    </ProtectedRoute>
                                }
                            />
                           <Route
                                path="/BigBonus"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <BigBonus />
                                    </ProtectedRoute>
                                }
                            />
                           <Route
                                path="/Big-Famielien"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <BigFamielien/>
                                    </ProtectedRoute>
                                }
                            />
                           <Route
                                path="/NeuKunde"
                                element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <AddClient/>
                                    </ProtectedRoute>
                                }
                            />
                           <Route
                                path="/All-Kunden"
                                element={
                                    <ProtectedRoute allowedRoles={['admin','service','superAdmin']}>
                                        <AllClients/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/client-details/:id" element={<ClientDetails />} />
                            <Route path="/Meine-Kunden" element={  <ProtectedRoute allowedRoles={['employee']}><ClientsList /></ProtectedRoute> } />
                           
                            <Route path="/sign/:uniqueId" element={<SignDocument />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </UserProvider>
    );
};

export default App;
